<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="$store.state.toast" color="primary">
      {{ $store.state.toastMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="$store.state.toast = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: 'BaseToast',
  data() {
    return {};
  },
};
</script>
