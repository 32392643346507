var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-loader'),_c('base-toast'),_c('v-row',{staticClass:"text-center"},[_c('v-col'),_c('v-col',{attrs:{"valign":"center"}},[_c('div',{staticClass:"card card-container"},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
var validate = ref.validate;
return [_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Create account ")])],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"First name","rules":{ required: true, min: 2 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"First name","required":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":{ required: true, min: 2 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Last name","required":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Username (email)","required":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":{ password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/, required: true, min: 6 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"counter":6,"error-messages":errors,"success":valid,"label":"Password","type":"password","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.clear}},[_vm._v("Clear")]),_c('v-spacer'),(!validated || invalid)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return validate()}}},[_vm._v("Sign in")]):_vm._e(),(!invalid && validated)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return handleSubmit(_vm.register)}}},[_vm._v("Sign up")]):_vm._e()],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" Back to login ")])],1)],1)],1)]}}])})],1)]),_c('v-col')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }