import axios from 'axios';
import authHeader from './auth-header';
import { PRODUCTION_API_URL } from '@/settings';

const API_URL = PRODUCTION_API_URL + 'properties/';

class PropertiesService {
  filterProperties(postParams) {
    return axios
      .post(API_URL + 'filtered', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  geoProperties(postParams) {
    return axios
      .post(API_URL + 'geo', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  batchProperties(postParams) {
    return axios
      .post(API_URL + 'batch', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  searchProperties(postParams) {
    return axios
      .post(API_URL + 'search', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  clusterProperties(postParams) {
    return axios
      .post(API_URL + 'cluster', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  propertiesCount(postParams) {
    return axios
      .post(API_URL + 'count', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  filters(postParams) {
    return axios
      .post(API_URL + 'filters', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  zoneoverlays(postParams) {
    return axios
      .post(API_URL + 'zoneoverlays', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  streets(postParams) {
    return axios
      .post(API_URL + 'streets', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  ccm(postParams) {
    return axios
      .post(API_URL + 'ccm', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  parcel(postParams) {
    return axios
      .post(API_URL + 'parcel', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new PropertiesService();
