<template>
  <v-dialog v-model="$store.state.propertyFilters" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card
      class="blue-grey--text darken-4--text blue-grey lighten-5"
      style="margin: 0 auto; padding: 15px; padding-top: 0px; width: 100%"
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="hidePropertyFilters">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Filter settings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="hidePropertyFilters"> Close </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear
        :style="{ visibility: loadingFilters || loadingUserFilters ? 'visible' : 'hidden' }"
        indeterminate
      ></v-progress-linear>
      <v-container v-if="!loadingFilters" light>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="selectedFilter"
              :items="userFilters"
              dense
              item-text="name"
              item-value="filter_json"
              outlined
              label="Saved filters"
              @input="filterUpdate"
            ></v-select>
          </v-col>
          <v-col> </v-col>
          <v-col>
            <v-text-field
              v-model="selectedFilterName"
              style="width: 200px"
              outlined
              dense
              label="filter name"
              hint="add name to save settings"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn outlined @click="saveFilter">Save</v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col class="pl-5 pr-5" style="width: 550px">
            <v-row v-if="propertiesFilters.zipcodes.length" style="height: 90px">
              <v-col>
                <v-autocomplete
                  v-model="zipcodeSel"
                  light
                  dense
                  :items="propertiesFilters.zipcodes"
                  item-text="zipcode"
                  item-value="zipcode"
                  label="Select zipcodes"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="propertiesFilters.zones.length" style="height: 90px">
              <v-col style="width: 350px">
                <v-autocomplete
                  v-model="zoneSel"
                  light
                  dense
                  :items="propertiesFilters.zones"
                  item-text="zone"
                  item-value="zone"
                  label="Select zones"
                  hide-selected
                  outlined
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="propertiesFilters.use_desc.length" style="height: 90px">
              <v-col>
                <v-autocomplete
                  v-model="useDescSel"
                  light
                  dense
                  :items="propertiesFilters.use_desc"
                  item-text="usedesc"
                  item-value="usedesc"
                  label="Select use desc"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="propertiesFilters.proptype.length" style="height: 90px">
              <v-col>
                <v-autocomplete
                  v-model="proptypeSel"
                  light
                  dense
                  :items="propertiesFilters.proptype"
                  item-text="proptype"
                  item-value="proptype"
                  label="Select property type"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="propertiesFilters.zone_overlays.length && this.$store.state.activeGeoConfig.zone_overlays"
              style="height: 90px"
            >
              <v-col>
                <v-autocomplete
                  v-model="zoneOverlaySel"
                  light
                  dense
                  :items="propertiesFilters.zone_overlays"
                  item-text="zone"
                  item-value="zone"
                  label="Select zone overlays"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="propertiesFilters.use_code.length && this.$store.state.activeGeoConfig.use_code"
              style="height: 90px"
            >
              <v-col>
                <v-autocomplete
                  v-model="useCodeSel"
                  light
                  dense
                  :items="propertiesFilters.use_code"
                  item-text="usecode"
                  item-value="usecode"
                  label="Select use code"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="propertiesFilters.ludesc.length && this.$store.state.activeGeoConfig.ludesc"
              style="height: 90px"
            >
              <v-col>
                <v-autocomplete
                  v-model="luDescSel"
                  light
                  dense
                  :items="propertiesFilters.ludesc"
                  item-text="ludesc"
                  item-value="ludesc"
                  label="Select land use desc"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="propertiesFilters.lbcs_function.length && this.$store.state.activeGeoConfig.lbcs_function"
              style="height: 90px"
            >
              <v-col>
                <v-autocomplete
                  v-model="lbcsFunctionSel"
                  light
                  dense
                  :items="propertiesFilters.lbcs_function"
                  item-text="lbcsfunction"
                  item-value="lbcsfunction"
                  label="Select property function"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="propertiesFilters.ccm.length && this.$store.state.activeGeoConfig.ccm" style="height: 90px">
              <v-col>
                <v-autocomplete
                  v-model="ccmSel"
                  light
                  dense
                  :items="propertiesFilters.ccm"
                  item-text="transect"
                  item-value="transect"
                  label="Select CCM policy"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!---
            <v-row
              v-if="propertiesFilters.violations.length && this.$store.state.activeGeoConfig.violations"
              style="height: 90px"
            >
              <v-col>
                <v-autocomplete
                  v-model="violationsSel"
                  light
                  dense
                  :items="propertiesFilters.violations"
                  item-text="last_activity"
                  item-value="last_activity"
                  label="Select violations"
                  hide-selected
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            --->
            <v-row>
              <v-col>
                <v-checkbox
                  v-if="this.$store.state.activeGeoConfig.streetside"
                  id="streetside"
                  v-model="streetSideSel"
                  name="streetside"
                  label="Show only street side"
                  true-value="1"
                  false-value="0"
                />
                <v-checkbox
                  v-if="this.$store.state.activeGeoConfig.llcOwned"
                  id="llcOwned"
                  v-model="llcOwnedSel"
                  name="llcOwned"
                  label="Include LLC owned properties"
                  true-value="1"
                  false-value="0"
                />
                <v-checkbox
                  v-if="this.$store.state.activeGeoConfig.violations"
                  id="violations"
                  v-model="isViolationSel"
                  name="violations"
                  label="Show only violations"
                  true-value="1"
                  false-value="0"
                />
                <!---
                <v-checkbox
                  id="sevicerequests"
                  v-model="isServiceRequestSel"
                  name="sevicerequests"
                  label="Show only service requests"
                  true-value="1"
                  false-value="0"
                />
                --->
                <v-checkbox
                  v-if="this.$store.state.activeGeoConfig.OIC"
                  id="OIC"
                  v-model="OICSel"
                  name="OIC"
                  label="Include O.I.C properties"
                  true-value="1"
                  false-value="0"
                />
                <v-checkbox
                  v-if="this.$store.state.activeGeoConfig.uspsVacancy"
                  id="uspsVacancy"
                  v-model="uspsVacancy"
                  name="uspsVacancy"
                  label="Show only USPS vacancies"
                  true-value="1"
                  false-value="0"
                />
                <v-checkbox
                  v-if="this.$store.state.activeGeoConfig.hideGov"
                  id="hideGov"
                  v-model="is_govhide"
                  name="hideGov"
                  label="Hide government buildings"
                  true-value="1"
                  false-value="0"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-5 pr-5" style="width: 550px">
            <v-row v-if="this.$store.state.activeGeoConfig.acreValue">
              <v-col cols="3">
                <h4>Acres</h4>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="acreValueRangeMin"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Min"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-icon color="green"> mdi-minus </v-icon>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="acreValueRangeMax"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Max"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="this.$store.state.activeGeoConfig.totalValue">
              <v-col cols="3">
                <h4>Total value</h4>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="totalValueRangeMin"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Min"
                  prefix="$"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-icon color="green"> mdi-minus </v-icon>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="totalValueRangeMax"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Max"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="this.$store.state.activeGeoConfig.marketPrice">
              <v-col cols="3">
                <h4>Market price</h4>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="marketPriceRangeMin"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Min"
                  prefix="$"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-icon color="green"> mdi-minus </v-icon>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="marketPriceRangeMax"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Max"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="this.$store.state.activeGeoConfig.salePrice">
              <v-col cols="3">
                <h4>Sale price</h4>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="salePriceRangeMin"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Min"
                  prefix="$"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-icon color="green"> mdi-minus </v-icon>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="salePriceRangeMax"
                  hide-details="true"
                  type="number"
                  outlined
                  dense
                  light
                  label="Max"
                  prefix="$"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="this.$store.state.activeGeoConfig.soldYear">
              <v-col cols="3">
                <h4>Sold before</h4>
              </v-col>
              <v-col cols="9">
                <v-select v-model="soldYear" :items="years" dense outlined label="select year"></v-select>
              </v-col>
            </v-row>
            <v-row v-if="this.$store.state.activeGeoConfig.builtYear">
              <v-col cols="3">
                <h4>Built before</h4>
              </v-col>
              <v-col cols="9">
                <v-select v-model="builtYear" :items="years" dense outlined label="select year"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip v-if="propertiesFilterCount" class="ma-2" color="secondary">
                  {{ propertiesFilterCount }} properties found
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="hidePropertyFilters">Search</v-btn>
                <v-btn class="ml-3" color="secondary" @click="getPropertiesCount">Count</v-btn>
                <v-btn class="ml-3" outlined @click="clearSearch">Clear</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import PropertiesService from '@/services/properties.service';
import UserService from '@/services/user.service';
import { mapGetters } from 'vuex';

export default {
  name: 'FilterProperties',
  components: {},
  data() {
    return {
      loadingFilters: false,
      loadingUserFilters: false,
      propertiesFilterCount: 0,
      propertiesFilters: {
        zones: [],
        zone_overlays: [],
        use_code: [],
        use_desc: [],
        ludesc: [],
        proptype: [],
        zipcodes: [],
        lbcs_function: [],
        violations: [],
        ccm: [],
      },
      userFilters: [],
      selectedFilter: '',
      selectedFilterName: '',
      llcOwnedSel: '1',
      OICSel: '0',
      uspsVacancy: '0',
      isViolationSel: '0',
      //isServiceRequestSel: '0',
      is_govhide: '1',
      streetSideSel: '0',
      min: 1000,
      max: 1000000,
      zipcodeSel: [],
      zoneOverlaySel: [],
      zoneSel: [],
      useDescSel: [],
      useCodeSel: [],
      luDescSel: [],
      proptypeSel: [],
      violationsSel: [],
      lbcsFunctionSel: [],
      ccmSel: [],
      totalValueRange: [0, 10000000],
      totalValueRangeMin: 0,
      totalValueRangeMax: 1000000,
      salePriceRange: [0, 10000000],
      salePriceRangeMin: 0,
      salePriceRangeMax: 10000000,
      marketPriceRange: [0, 10000000],
      marketPriceRangeMin: 0,
      marketPriceRangeMax: 10000000,
      acreMin: 0,
      acreMax: 50,
      acreValueRange: [0, 10],
      acreValueRangeMin: 0,
      acreValueRangeMax: 1000,
      soldYear: 2023,
      builtYear: 2023,
      years: [
        2023,
        2022,
        2021,
        2020,
        2019,
        2018,
        2017,
        2016,
        2015,
        2014,
        2013,
        2012,
        2011,
        2010,
        2009,
        2008,
        2007,
        2006,
        2005,
        2004,
        2003,
        2002,
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
  },
  watch: {
    activeGeoId() {
      this.clearSearch();
      this.getFilters();
      this.getUserFilters();
      this.getPropertiesCount();
    },
  },
  mounted() {
    if (this.$store.state.activeGeoId) {
      this.filterUpdateDefault();
      this.getFilters();
      this.getUserFilters();
      this.getPropertiesCount();
    }
  },
  methods: {
    // methods on close
    hidePropertyFilters() {
      this.prepQueryString();
      this.$emit('refresh-data', true);
      this.getPropertiesCount();
      this.$store.commit('showPropertyFilters', false);
    },
    // count properties
    async getPropertiesCount() {
      this.prepQueryString();
      var postParams = this.$store.state.propertyFilterConfig;
      PropertiesService.propertiesCount(postParams)
        .then((response) => {
          this.propertiesFilterCount = response.cnt;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Get all filter data criteria
    getFilters() {
      this.loadingFilters = true;
      var params = {};
      params = {
        geo_id: this.$store.state.activeGeoId,
      };
      PropertiesService.filters(params)
        .then((response) => {
          this.propertiesFilters = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
    getUserFilters() {
      this.loadingUserFilters = true;
      UserService.userFilters()
        .then((response) => {
          this.userFilters = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingUserFilters = false));
    },
    // save filter for a user
    saveFilter() {
      this.loadingUserFilters = true;
      this.prepQueryString();
      var postParamsFinal = {};
      postParamsFinal.name = this.selectedFilterName;
      postParamsFinal.filter_json = JSON.stringify(this.$store.state.propertyFilterConfig);
      UserService.saveUserFilters(postParamsFinal)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.selectedFilterName = '';
          this.getUserFilters();
          this.loadingUserFilters = false;
        });
    },
    // update selected user filter
    filterUpdate() {
      this.filterUpdateFields(JSON.parse(this.selectedFilter));
    },

    // update selected user filter
    filterUpdateDefault() {
      this.filterUpdateFields(this.$store.state.propertyFilterConfig);
    },
    // set fields
    filterUpdateFields(filterData) {
      this.zipcodeSel = filterData.zipcode;
      this.zoneOverlaySel = filterData.zone_overlay;
      this.totalValueRangeMin = filterData.total_value[0];
      this.totalValueRangeMax = filterData.total_value[1];
      this.salePriceRangeMin = filterData.sale_price[0];
      this.salePriceRangeMax = filterData.sale_price[1];
      this.marketPriceRangeMin = filterData.market_price[0];
      this.marketPriceRangeMax = filterData.market_price[1];
      this.zoneSel = filterData.zone;
      this.acreValueRangeMin = filterData.acre_value[0];
      this.acreValueRangeMax = filterData.acre_value[1];
      this.useDescSel = filterData.use_desc;
      this.useCodeSel = filterData.use_code;
      this.luDescSel = filterData.ludesc;
      this.proptypeSel = filterData.proptype;
      this.lbcsFunctionSel = filterData.lbcs_function;
      this.violationsSel = filterData.violations;
      this.ccmSel = filterData.ccm;
      this.soldYear = filterData.sold_year;
      this.builtYear = filterData.builtYear;
      this.is_govhide = filterData.is_govhide;
      this.isViolationSel = filterData.is_violation;
      //this.isServiceRequestSel = filterData.is_servicerequest;
      this.streetSideSel = filterData.is_streetside;
    },
    // clear out filter and reset to defaults
    clearSearch() {
      this.zipcodeSel = [];
      this.zoneOverlaySel = [];
      this.zoneSel = [];
      this.useDescSel = [];
      this.useCodeSel = [];
      this.luDescSel = [];
      this.proptypeSel = [];
      this.lbcsFunctionSel = [];
      this.violationsSel = [];
      this.ccmSel = [];
      this.totalValueRangeMin = 0;
      this.totalValueRangeMax = 10000000;
      this.salePriceRangeMin = 0;
      this.salePriceRangeMax = 10000000;
      this.marketPriceRangeMin = 0;
      this.marketPriceRangeMax = 10000000;
      this.acreValueRangeMin = 0;
      this.acreValueRangeMax = 1000;
      this.soldYear = 2022;
      this.builtYear = 2022;
      this.is_govhide = 1;
      this.isViolationSel = 0;
      //this.isServiceRequestSel = 0;
      this.streetSideSel = 0;
    },
    // Prepare query string for searching
    prepQueryString() {
      var params = {};
      params = {
        geo_id: this.$store.state.activeGeoId,
        zipcode: this.zipcodeSel,
        zone_overlay: this.zoneOverlaySel,
        total_value: [this.totalValueRangeMin, this.totalValueRangeMax],
        sale_price: [this.salePriceRangeMin, this.salePriceRangeMax],
        market_price: [this.marketPriceRangeMin, this.marketPriceRangeMax],
        zone: this.zoneSel,
        acre_value: [this.acreValueRangeMin, this.acreValueRangeMax],
        use_desc: this.useDescSel,
        use_code: this.useCodeSel,
        ludesc: this.luDescSel,
        proptype: this.proptypeSel,
        lbcs_function: this.lbcsFunctionSel,
        is_streetside: this.streetSideSel,
        llc_include: this.llcOwnedSel,
        oic_include: this.OICSel,
        usps_vacancy: this.uspsVacancy,
        is_violation: this.isViolationSel,
        //is_servicerequest: this.isServiceRequestSel,
        is_govhide: this.is_govhide,
        violations: this.violationsSel,
        ccm: this.ccmSel,
        sold_year: this.soldYear,
        builtYear: this.builtYear,
        boundary: this.$store.state.mapBoundary,
      };
      this.$store.commit('setPropertyFilters', params);
    },
  },
};
</script>
