import axios from 'axios';
import authHeader from './auth-header';
import { PRODUCTION_API_URL } from '@/settings';

const API_URL = PRODUCTION_API_URL + 'user/';

class UserService {
  userFilters() {
    return axios
      .get(API_URL + 'filters', {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  saveUserFilters(postParams) {
    return axios
      .post(API_URL + 'filters', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getContactInfo(postParams) {
    return axios
      .post(API_URL + 'contacts/search', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getContactDetails(postParams) {
    return axios
      .post(API_URL + 'contacts/lookup', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new UserService();
