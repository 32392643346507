<template>
  <v-col v-if="checkActiveStatDisplayName">
    <v-card class="pa-3" style="min-width: 130px">
      <h5 class="secondary--text">{{ getActiveStatDisplayName }}</h5>
      <h3 class="primary--text" style="margin-bottom: 0">
        {{ getActiveStatValue }}
      </h3>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';

export default {
  name: 'BaseCardChart',
  components: {},
  props: {
    name: { type: String, required: true },
    value: { type: Number, required: true },
  },
  data() {
    return {
      pName: this.name,
      pValue: this.value,
      activeStat: {},
      approvedStats: [
        {
          property: 'permits-issued',
          display_name: 'Active STR Permits',
          data_type: 'int',
        },
        {
          property: 'property-count',
          display_name: 'STR Properties',
          data_type: 'int',
        },
        {
          property: 'nightlyrate-mean',
          display_name: 'Average Nightly Rate',
          data_type: 'currency',
        },
        {
          property: 'occupancyrate-mean',
          display_name: 'Occupancy Rate',
          data_type: 'rate',
        },
        {
          property: 'annualrevenue-mean',
          display_name: 'Annual Revenue',
          data_type: 'currency',
        },
        {
          property: 'average-days-booking',
          display_name: 'Average Days Booked',
          data_type: 'int',
        },
      ],
    };
  },
  computed: {
    checkActiveStatDisplayName: function () {
      if (this.approvedStats.find((s) => s.property === this.pName)) {
        return true;
      } else {
        return false;
      }
    },
    getActiveStatDisplayName: function () {
      return this.approvedStats.find((s) => s.property === this.pName).display_name;
    },
    getActiveStatValue: function () {
      return this.formatValue(this.pValue, this.approvedStats.find((s) => s.property === this.pName).data_type);
    },
  },
  methods: {
    formatValue: function (value, dataType) {
      switch (dataType) {
        case 'int':
          return Number(value).toLocaleString();
        case 'rate':
          return (parseFloat(value) * 100).toFixed(2) + '%';
        case 'percent':
          return parseFloat(value).toFixed(2) + '%';
        case 'currency':
          return Number(parseFloat(value).toFixed(2)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        case 'time':
          // return (parseFloat(value) * 100).toFixed(2);
          return moment.utc(parseFloat(value) * 1000).format('mm:ss');
        default:
          return value;
      }
    },
  },
};
</script>
