import axios from 'axios';
import authHeader from './auth-header';
import { PRODUCTION_API_URL } from '@/settings';

const API_URL = PRODUCTION_API_URL + 'batches';

class BatchesService {
  getAll() {
    return axios
      .get(API_URL + '', {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  refreshBatch(postParams) {
    return axios
      .post(API_URL + '/refresh', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  refreshBatchCustom(postParams) {
    return axios
      .post(API_URL + '/custom/refresh', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getBatchFileForDownload(postParams) {
    return axios
      .post(API_URL + '/download', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getBatchCustomFileForDownload(postParams) {
    return axios
      .post(API_URL + '/custom/download', postParams, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new BatchesService();
