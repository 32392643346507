import axios from 'axios';
import { PRODUCTION_API_URL } from '@/settings';

const API_URL = PRODUCTION_API_URL + 'user/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'create', {
      username: user.username,
      password: user.password,
      first_name: user.firstName,
      last_name: user.lastName,
    });
  }
}

export default new AuthService();
