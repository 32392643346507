import axios from 'axios';
import authHeader from './auth-header';
import { PRODUCTION_API_URL } from '@/settings';

const API_URL = PRODUCTION_API_URL + 'uploads';

class UploadsService {
  getAll() {
    return axios
      .get(API_URL + '', {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  uploadFile(file) {
    return axios
      .post(API_URL + '/custom', file, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new UploadsService();
