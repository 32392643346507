<template>
  <!---
    // Property details dialog
    ---->
  <v-dialog v-model="$store.state.propertyDetail" width="70%">
    <v-card>
      <v-card-title class="headline blue-grey lighten-2"></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-chip class="ma-2" color="balck" outlined>
              <strong>Assessed value: {{ formatCurrency($store.state.propertyDetailData.parcel.parval) }}</strong>
            </v-chip>
            <div align-center>(assessed on {{ $store.state.propertyDetailData.parcel.assessdate }})</div>
          </v-col>
          <v-col>
            <v-chip class="ma-2" color="success" outlined>
              <strong>Market value: {{ formatCurrency($store.state.propertyDetailData.parcel.marketprice) }}</strong>
            </v-chip>
          </v-col>
          <v-col>
            <v-chip class="ma-2" color="secondary" outlined>
              <v-icon
                v-if="$store.state.propertyDetailData.parcel.offerprice < $store.state.propertyDetailData.parcel.parval"
                color="red"
                left
              >
                mdi-exclamation-thick
              </v-icon>
              <v-icon
                v-if="$store.state.propertyDetailData.parcel.offerprice > $store.state.propertyDetailData.parcel.parval"
                color="green"
                left
              >
                mdi-star
              </v-icon>
              <strong>Offer price: {{ formatCurrency($store.state.propertyDetailData.parcel.offerprice) }}</strong>
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" :href="parcelViewLink" target="_blank">Parcel viewer</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" :href="assessorViewLink" target="_blank">Assessor of property</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1"> <strong>Owner:</strong> {{ $store.state.propertyDetailData.parcel.owner }} </v-col>
          <v-col class="pt-1 pb-1">
            <strong>Address:</strong> {{ $store.state.propertyDetailData.parcel.address }}
            {{ $store.state.propertyDetailData.parcel.scity }}
            {{ $store.state.propertyDetailData.parcel.szip }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1">
            <strong>Parcel number:</strong> {{ $store.state.propertyDetailData.parcel.parcelnumb }}
          </v-col>
          <v-col v-if="this.$store.state.activeGeoConfig.zone_overlays" class="pt-1 pb-1">
            <strong>Zone overlay:</strong>
            <span v-for="item in $store.state.propertyDetailData.parcel_details.zone_overlay" :key="item.zone">
              {{ item.zone }},
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1"> <strong>Use:</strong> {{ $store.state.propertyDetailData.parcel.usedesc }} </v-col>
          <v-col class="pt-1 pb-1"> <strong>Zone:</strong> {{ $store.state.propertyDetailData.parcel.zoning }} </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1">
            <strong>Sale price:</strong> {{ formatCurrency($store.state.propertyDetailData.parcel.saleprice) }}
          </v-col>
          <v-col class="pt-1 pb-1">
            <strong>Sale date:</strong> {{ $store.state.propertyDetailData.parcel.saledate }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1">
            <strong>Acres:</strong> {{ formatAcre($store.state.propertyDetailData.parcel.ll_gisacre) }}
          </v-col>
          <v-col class="pt-1 pb-1">
            <strong>Square feet:</strong> {{ $store.state.propertyDetailData.parcel.ll_bldg_footprint_sqft }}
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="this.$store.state.activeGeoConfig.zone_overlays" class="pt-1 pb-1">
            <strong>CCM Policy: </strong>
            <span v-for="item in $store.state.propertyDetailData.parcel_details.ccm" :key="item.transect">
              {{ item.transect }}
            </span>
          </v-col>
          <v-col v-if="this.$store.state.activeGeoConfig.llc" class="pt-1 pb-1">
            <strong>LLC Info: </strong>
            <span v-for="item in $store.state.propertyDetailData.parcel_details.llc" :key="item.name">
              {{ item.name }} - {{ item.org_name }} - {{ item.first_name }} {{ item.last_name }} - {{ item.org_name }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="
              this.$store.state.activeGeoConfig.permits && $store.state.propertyDetailData.parcel_details.permits > 0
            "
            class="pt-1 pb-1"
          >
            <v-expansion-panels multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>Permits</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-for="item in $store.state.propertyDetailData.parcel_details.permits" :key="item.applicant">
                    {{ item.applicant }} - {{ item.contact }} - {{ item.permit_subtype_description }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col
            v-if="
              this.$store.state.activeGeoConfig.violations &&
              $store.state.propertyDetailData.parcel_details.violations.length > 0
            "
            class="pt-1 pb-1"
          >
            <v-expansion-panels multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>Violations</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-for="item in $store.state.propertyDetailData.parcel_details.violations" :key="item.name">
                    {{ item.reported_problem }} - {{ item.violations_noted }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <!---
        <v-row v-if="!showContactInfo">
          <v-col align="center">
            <v-btn color="primary" @click="getContactInfo"> Lookup contact info </v-btn>
          </v-col>
        </v-row>
        ----->
      <v-progress-linear
        :style="{ visibility: loadingContactDetails ? 'visible' : 'hidden' }"
        indeterminate
      ></v-progress-linear>
      <v-row v-if="contactDetails" class="pl-3 pr-3">
        <v-divider></v-divider>
        <v-col v-for="(field, i) in contactDetails.phones" :key="i">
          <v-card class="mx-auto">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ field.first_name }} {{ field.last_name }}</v-list-item-title>
                <v-list-item-title>{{ field.phone | phone }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ String(field.phone_start) | formatDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="contactDetails" class="pl-3 pr-3">
        <v-col v-for="(field, i) in contactDetails.emails" :key="i">
          <v-card class="mx-auto">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ field.first_name }} {{ field.last_name }}</v-list-item-title>
                <v-list-item-title>{{ field.email }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ String(field.email_start) | formatDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="hidePropertyDetail()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UserService from '@/services/user.service';
import { mapGetters } from 'vuex';
import numeral from 'numeral';

export default {
  name: 'PropertyDetail',
  components: {},
  data() {
    return {
      numeral,
      loadingContactDetails: false,
      contactDetails: [],
    };
  },
  computed: {
    ...mapGetters({
      propertyDetailData: 'getActiveParcel',
    }),
    parcelViewLink() {
      return this.$store.state.activeGeoParcelLink.replace(
        '{parcelnum}',
        this.$store.state.propertyDetailData.parcel.parcelnumb,
      );
    },
    assessorViewLink() {
      return this.$store.state.activeGeoAssessorLink.replace(
        '{parcelnum}',
        this.$store.state.propertyDetailData.parcel.parcelnumb,
      );
    },
  },
  watch: {
    propertyDetailData() {
      this.getContactInfo();
    },
  },
  methods: {
    hidePropertyDetail() {
      this.$store.commit('showPropertyDetail', false);
    },
    getContactInfo() {
      this.loadingContactDetails = true;
      UserService.getContactDetails({
        parcel_num: this.$store.state.propertyDetailData.parcel.parcelnumb,
        add1: this.$store.state.propertyDetailData.parcel.mailadd,
        geo_id: this.$store.state.activeGeoId,
      })
        .then((response) => {
          this.contactDetails = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingContactDetails = false));
    },
    // Acre formatting
    formatAcre(val) {
      if (val >= this.acreMax) {
        return this.numeral(val).format('0.00') + '+';
      } else {
        return this.numeral(val).format('0.00');
      }
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Format date
    parseContactdate(str) {
      if (!/^(\d){8}$/.test(str)) return 'invalid date';
      var y = str.substr(0, 4),
        m = str.substr(4, 2),
        d = str.substr(6, 2);

      var ddate = new Date(y, m, d);
      return ddate.getMonth() + 1 + '/' + ddate.getDate() + '/' + ddate.getFullYear();
    },
  },
};
</script>
