<template>
  <v-dialog v-model="$store.state.loading" persistent fullscreen>
    <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
      <v-layout justify-center align-center>
        <v-progress-circular color="primary" :size="70" :width="7" indeterminate></v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>
<script>
export default {
  name: 'BaseLoader',
  data() {
    return {};
  },
};
</script>
