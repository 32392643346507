<template>
  <div>
    <base-loader />
    <base-toast />
    <v-row class="text-center">
      <v-col></v-col>
      <v-col valign="center">
        <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit, validate }">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <ValidationProvider v-slot="{ errors, valid }" name="username" rules="required">
                <v-text-field
                  v-model="user.username"
                  :error-messages="errors"
                  :success="valid"
                  label="Username"
                  required
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors, valid }" name="Password" :rules="{ required: true }">
                <v-text-field
                  v-model="user.password"
                  :error-messages="errors"
                  :success="valid"
                  label="Password"
                  type="password"
                  required
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="!validated || invalid" color="primary" @click="validate()">Sign in</v-btn>
              <v-btn v-if="!invalid && validated" color="primary" @click="handleSubmit(login)">Sign in</v-btn>
            </v-card-actions>
            <v-row>
              <v-col>
                <router-link to="/forgot" style="font-size: 0.85em"> Forget your password? </router-link>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col> Dont' have an account? <router-link to="/register"> Sign up </router-link> </v-col>
            </v-row>
          </v-card>
        </ValidationObserver>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
</template>
<script>
import User from '../models/user';
import BaseLoader from '@/components/BaseLoader';
import BaseToast from '@/components/BaseToast';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Login',
  components: {
    BaseLoader,
    BaseToast,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      user: new User('', '', '', ''),
      message: '',
      errors: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    login() {
      this.$store.commit('showLoading', true);
      this.$store.dispatch('auth/login', this.user).then(
        () => {
          this.$store.commit('showLoading', false);
          this.$router.push('/properties');
        },
        (error) => {
          this.$store.commit('showLoading', false);
          this.message = (error.response && error.response.data.message) || error.toString();
          this.$store.commit('showToast', { active: true, message: this.message });
        },
      );
    },
  },
};
</script>
