<template>
  <v-row class="text-center">
    <v-col> </v-col>
    <v-col valign="center">
      <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit, validate }">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Forgot your password?</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            Enter your email associated with your account and we'll send a link to reset your password.
          </v-card-text>
          <v-card-text>
            <ValidationProvider v-slot="{ errors, valid }" name="email" rules="required|email">
              <v-text-field
                v-model="user.email"
                :error-messages="errors"
                :success="valid"
                label="E-mail"
                required
              ></v-text-field>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!validated || invalid" color="primary" @click="validate()">Send reset email</v-btn>
            <v-btn v-if="validated && !invalid" color="primary" @click="handleSubmit(forgot)">Send reset email</v-btn>
          </v-card-actions>
          <v-row>
            <v-col>
              <router-link to="/login" style="font-size: 0.85em"> Back to login </router-link>
            </v-col>
          </v-row>
        </v-card>
      </ValidationObserver>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>
<script>
import User from '../models/user';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Forgot',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      user: new User('', '', '', ''),
      loading: false,
      message: '',
      errors: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    forgot() {
      this.loading = true;
      this.$store.dispatch('auth/login', this.user).then(
        () => {
          this.$router.push('/profile');
        },
        (error) => {
          this.loading = false;
          this.message = (error.response && error.response.data) || error.message || error.toString();
        },
      );
    },
  },
};
</script>
