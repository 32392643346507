import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Forgot from '@/views/Forgot.vue';
import Register from '@/views/Register.vue';
import Profile from '@/views/Profile.vue';
import BoardUser from '@/views/BoardUser.vue';

import PropertyExplorer from '@/views/PropertyExplorer.vue';
import PropertyGrid from '@/views/PropertyGrid.vue';
import BatchUpload from '@/views/BatchUpload.vue';
import WidgetExplorer from '@/views/WidgetExplorer.vue';
import WidgetExplorerCluster from '@/views/WidgetExplorerCluster.vue';
import WidgetExplorerHeatMap from '@/views/WidgetExplorerHeatMap.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/home',
      component: Home,
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/register',
      component: Register,
    },
    {
      path: '/forgot',
      component: Forgot,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/properties',
      name: 'propertyexplorer',
      component: PropertyExplorer,
    },
    {
      path: '/uploads',
      name: 'batchupload',
      component: BatchUpload,
    },
    {
      path: '/widget',
      name: 'widgetexplorer',
      component: WidgetExplorer,
    },
    {
      path: '/widgetcluster',
      name: 'widgetexplorercluster',
      component: WidgetExplorerCluster,
    },
    {
      path: '/widgetheatmap',
      name: 'widgetexplorerheatmap',
      component: WidgetExplorerHeatMap,
    },
    {
      path: '/grid',
      name: 'propertygrid',
      component: PropertyGrid,
    },
    {
      path: '/user',
      name: 'user',
      component: BoardUser,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

Vue.router = router;

export default router;
