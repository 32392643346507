<template>
  <div>
    <!-- 
    // Main logged in app 
    --->
    <v-app v-if="currentUser">
      <v-card>
        <v-navigation-drawer permanent expand-on-hover app>
          <!---
          <v-list>
            <v-list-item link>
              <v-list-item-content>
                ssss
                <v-list-item-title class="title"> {{ currentUser.username }} </v-list-item-title>
                <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          --->
          <img src="./assets/aicre_red.gray.png" height="48" />
          <v-divider></v-divider>
          <v-list nav dense>
            <v-list-item link :to="{ name: 'propertygrid' }">
              <v-list-item-icon>
                <v-icon>mdi-view-module</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Grid View</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="{ name: 'propertyexplorer' }">
              <v-list-item-icon>
                <v-icon>mdi-map</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Map View</v-list-item-title>
            </v-list-item>
            <!---
            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            --->
            <v-list-item link :to="{ name: 'batchupload' }">
              <v-list-item-icon>
                <v-icon>mdi-file-upload-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Uploads</v-list-item-title>
            </v-list-item>
            <!---
            <v-list-item link>
              <v-list-item-icon>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Subscription</v-list-item-title>
            </v-list-item>
            ---->
            <v-list-item link @click="logOut">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-app-bar app class="blue-grey lighten-4">
        <v-row align="center">
          <v-col style="max-width: 350px">
            <v-select
              v-model="geoId"
              class="mt-6"
              :items="geos"
              dense
              item-text="name"
              item-value="id"
              outlined
              label="Select a county"
              @input="selectGeo"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <v-footer app variant="primary">
        <span>Copyright AiCRE Real Estate, LLC {{ new Date().getFullYear() }} </span>
      </v-footer>
    </v-app>
    <!--- 
    // Main login window 
    --->
    <v-app v-if="!currentUser">
      <v-main>
        <v-container fluid>
          <img src="./assets/aicre_red.gray.png" height="80" />
          <router-view />
        </v-container>
      </v-main>
      <v-footer app variant="primary">
        <span>Copyright AiCRE Real Estate, LLC {{ new Date().getFullYear() }} </span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      geoId: this.$store.state.activeGeoId,
      geos: [
        {
          name: 'Davidson County, TN',
          id: 47037,
          longlat: [-86.7816, 36.1627],
          parcellink: 'https://maps.nashville.gov/ParcelViewer/?parcelID={parcelnum}',
          assessorlink: 'http://www.padctn.org/prc/#/search/1?searchType=parcel&searchTerm={parcelnum}&searchNumber=',
          config: {
            streetside: true,
            llcOwned: true,
            violations: true,
            usecode: true,
            zone_overlays: true,
            use_desc: true,
            ludesc: false,
            lbcs_function: false,
            OIC: true,
            uspsVacancy: true,
            hideGov: true,
            acreValue: true,
            totalValue: true,
            marketPrice: true,
            salePrice: true,
            soldYear: true,
            builtYear: false,
            proptype: false,
            ccm: true,
            llc: true,
            permits: true,
          },
        },
        {
          name: 'Hamilton County, TN',
          id: 47065,
          longlat: [-85.3097, 35.0456],
          parcellink: 'https://ssssss{parcelnum}',
          assessorlink: 'http://ssss{parcelnum}&searchNumber=',
          config: {
            streetside: false,
            llcOwned: true,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: true,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: true,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: true,
            salePrice: true,
            soldYear: true,
            builtYear: false,
            proptype: false,
            ccm: false,
            llc: true,
            permits: false,
          },
        },
        {
          name: 'Montgomery County, TN',
          id: 6901,
          longlat: [-87.3595, 36.5298],
          parcellink: 'https://ssssss{parcelnum}',
          assessorlink: 'http://ssss{parcelnum}&searchNumber=',
          config: {
            streetside: false,
            llcOwned: true,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: false,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: false,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: false,
            salePrice: true,
            soldYear: true,
            builtYear: true,
            proptype: true,
            ccm: true,
            llc: true,
            permits: false,
          },
        },
        {
          name: 'Flagler County, FL',
          id: 6909,
          longlat: [-81.127, 29.475],
          parcellink:
            'https://qpublic.schneidercorp.com/Application.aspx?App=FlaglerCountyFL&Layer=Parcels&PageType=Search',
          assessorlink: '',
          config: {
            streetside: false,
            llcOwned: false,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: false,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: false,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: false,
            salePrice: true,
            soldYear: true,
            builtYear: false,
            proptype: false,
            ccm: false,
            llc: false,
            permits: false,
          },
        },
        {
          name: 'Volusia County, FL',
          id: 6908,
          longlat: [-81.0755, 29.028],
          parcellink: '',
          assessorlink: '',
          config: {
            streetside: false,
            llcOwned: false,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: true,
            ludesc: false,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: false,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: false,
            salePrice: true,
            soldYear: true,
            builtYear: false,
            proptype: false,
            ccm: false,
            llc: false,
            permits: false,
          },
        },
        /*
        {
          name: 'Jefferson County, KY',
          id: 6902,
          longlat: [-85.764771, 38.328732],
          parcellink: 'https://ssssss{parcelnum}',
          assessorlink: 'http://ssss{parcelnum}&searchNumber=',
          config: {
            streetside: false,
            llcOwned: false,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: false,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: false,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: false,
            salePrice: true,
            soldYear: true,
            builtYear: true,
            proptype: true,
            ccm: true,
            llc: false,
            permits: false,
          },
        },
        */
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
  },
  mounted() {
    this.$vuetify.theme.themes.light.primary = '#333333';
    this.$vuetify.theme.themes.light.secondary = '#5b667c';
    this.$vuetify.theme.themes.light.accent = '#E31E26';
  },
  methods: {
    selectGeo() {
      let geo = this.geos.filter((item) => {
        return parseInt(item.id) === parseInt(this.geoId);
      });
      this.$store.commit('setGeo', geo[0]);
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
  },
};
</script>
