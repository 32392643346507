import { render, staticRenderFns } from "./BoardUser.vue?vue&type=template&id=259d7bd0&"
import script from "./BoardUser.vue?vue&type=script&lang=js&"
export * from "./BoardUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports