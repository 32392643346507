<template>
  <div style="margin-top: 0px">
    <base-loader />
    <!---
    // Batch Upoad view
    ---->
    <v-row v-if="activeGeoId">
      <v-col> </v-col>
      <v-col>
        <v-card class="pa-3">
          <v-text-field v-model="batchName" label="Batch name" required></v-text-field>
          <input ref="file" type="file" @change="uploadFile" />
          <v-divider></v-divider>
          <v-btn v-if="batchName && images" class="mt-3" color="secondary" @click="submitFile">Upload</v-btn>
        </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadsService from '@/services/uploads.service';
import BaseLoader from '@/components/BaseLoader';

export default {
  name: 'BatchUpload',
  components: { BaseLoader },
  data() {
    return {
      uploads: [],
      files: [],
      batchName: '',
      imageSet: '',
      images: '',
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  watch: {
    activeGeoId() {
      this.getUploads();
    },
  },
  mounted() {
    if (this.$store.state.activeGeoId) {
      this.getUploads();
    }
  },
  methods: {
    // Show property filter modal
    setGeoId(geoId) {
      this.$store.commit('setGeoId', { id: geoId });
    },
    uploadFile() {
      this.images = this.$refs.file.files[0];
    },
    submitFile() {
      this.$store.commit('showLoading', true);
      const formData = new FormData();
      formData.append('file', this.images);
      formData.append('geoId', this.$store.state.activeGeoId);
      formData.append('name', this.batchName);
      this.loadingFilters = true;
      UploadsService.uploadFile(formData)
        .then((response) => {
          this.uploads = response;
          this.$store.commit('showLoading', false);
          this.batchName = '';
          this.images = '';
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.$store.commit('showLoading', false);
        })
        .finally(() => (this.loadingFilters = false));
    },
    // Get all filter data criteria
    getUploads() {
      UploadsService.getAll()
        .then((response) => {
          this.uploads = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
  },
};
</script>
