import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#5B677C',
        secondary: '#192a3e',
      },
      dark: {
        primary: '#5B677C',
        secondary: '#192a3e',
      },
    },
  },
});
