var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"text-center"},[_c('v-col'),_c('v-col',{attrs:{"valign":"center"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
var validate = ref.validate;
return [_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Forgot your password?")])],1),_c('v-card-text',[_vm._v(" Enter your email associated with your account and we'll send a link to reset your password. ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"E-mail","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),(!validated || invalid)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return validate()}}},[_vm._v("Send reset email")]):_vm._e(),(validated && !invalid)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return handleSubmit(_vm.forgot)}}},[_vm._v("Send reset email")]):_vm._e()],1),_c('v-row',[_c('v-col',[_c('router-link',{staticStyle:{"font-size":"0.85em"},attrs:{"to":"/login"}},[_vm._v(" Back to login ")])],1)],1)],1)]}}])})],1),_c('v-col')],1)}
var staticRenderFns = []

export { render, staticRenderFns }