<template>
  <div style="margin-top: 0px">
    <base-loader />
    <v-btn
      color="secondary"
      style="position: fixed; top: 14px; left: 500px; z-index: 5"
      plain
      @click="showPropertyFilters"
      >Filter properties</v-btn
    >
    <v-badge
      v-if="propertiesFilterCount > 0"
      style="position: fixed; top: 22px; left: 670px; z-index: 5"
      color="error"
      :content="propertiesFilterCount.toLocaleString('en-US')"
    ></v-badge>
    <v-text-field
      v-model="searchPropertiesText"
      style="position: fixed; top: 14px; right: 14px; z-index: 5"
      label="Search all properties"
      prepend-icon="mdi-home-search"
      hide-details
      outlined
      clearable
      dense
      @keyup="searchPropertiesAll()"
    ></v-text-field>
    <v-card
      v-if="propertyQuickSearch.length > 0"
      style="position: absolute; right: 5px; z-index: 5; top: 20px; width: 290px; height: 500px; overflow: auto"
    >
      <v-list class="lighten-3">
        <v-list-item v-for="(field, i) in propertyQuickSearch" :key="i">
          <v-list-item-content style="cursor: pointer" @click="getParcelSearch(field.parcelnumb, field.mail_addstr)">
            <v-list-item-title v-text="field.parcelnumb"></v-list-item-title>
            <v-list-item-subtitle v-text="field.owner"></v-list-item-subtitle>
            <v-list-item-subtitle v-text="field.address"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <filter-properties @refresh-data="refreshData" />
    <property-detail />
    <v-row align="center">
      <v-col class="pt-0 pb-0" v-if="this.$store.state.activeGeoConfig.streetside">
        <v-switch v-model="mapConnectorStreet" label="Corridors" @change="getLayersStreets"></v-switch>
      </v-col>
      <v-col class="pt-0 pb-0" v-if="this.$store.state.activeGeoConfig.zone_overlays">
        <v-switch v-model="mapZoneOverlay" label="Zone Overlay" @change="getLayersZoneOverlay"></v-switch>
      </v-col>
      <v-col class="pt-0 pb-0" v-if="this.$store.state.activeGeoConfig.ccm">
        <v-switch v-model="mapCCM" label="CCM Policy" @change="getLayersCCM"></v-switch>
      </v-col>
      <v-col class="pt-0 pb-0" cols="5" style="text-align: right">
        <v-chip :ripple="false" color="secondary">
          {{ propertiesMapCount.toLocaleString('en-US') }} properties found in map area
        </v-chip>
      </v-col>
      <!---
      <v-col style="text-align: right">
        <v-text-field
          v-if="propertiesList.length > 0"
          v-model="searchPropertyListText"
          class="mb-0 pb-0 mt-0 pt-0"
          label="Quick search list"
          clearable
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      --->
    </v-row>
    <!----
    // Map view
    ------>
    <v-row>
      <v-col class="pt-0">
        <v-row>
          <v-col style="padding-top: 0px">
            <v-progress-linear
              :style="{
                visibility: loadingPropertiesGeo || loadingPropertiesCluster || loadingLayers ? 'visible' : 'hidden',
              }"
              indeterminate
            ></v-progress-linear>
            <div id="mapdiv" style="width: 100%; height: 600px"></div>
          </v-col>
        </v-row>
        <v-row>
          <!---
          <v-col>
            <v-radio-group v-model="mapType" class="pt-0 mt-0" row @change="changeMaps">
              <v-radio label="Street" value="street"></v-radio>
              <v-radio label="Light" value="light"></v-radio>
              <v-radio label="Dark" value="dark"></v-radio>
              <v-radio label="Street View" value="street-view"></v-radio>
            </v-radio-group>
          </v-col>
          --->
          <v-col v-if="mapCCM && this.$store.state.activeGeoConfig.ccm">
            <div v-for="item in CCMLegend" :key="item.name" style="float: left; padding: 4px; min-width: 60px">
              <div
                :style="`margin: 0 auto;background: ${item.color};width: 30px;height:30px;border-radius:30px;opacity: 0.4`"
              >
                &nbsp;
              </div>
              <div style="text-align: center; font-size: 0.55em">{{ item.name }}</div>
            </div>
          </v-col>
          <v-col v-if="mapConnectorStreet && this.$store.state.activeGeoConfig.streetside">
            <div style="float: left; padding: 4px; max-width: 60px">
              <div
                style="
                  margin: 0 auto;
                  background: #f54242;
                  width: 30px;
                  height: 30px;
                  border-radius: 30px;
                  opacity: 0.8;
                "
              >
                &nbsp;
              </div>
              <div style="text-align: center; font-size: 0.55em">Corridors</div>
            </div>
          </v-col>
          <v-col v-if="mapZoneOverlay && this.$store.state.activeGeoConfig.zone_overlays">
            <div v-for="item in zoneOverlayLegend" :key="item.name" style="float: left; padding: 4px; max-width: 60px">
              <div
                :style="`margin: 0 auto;background: ${item.color};width: 30px;height:30px;border-radius:30px;opacity: 0.4`"
              >
                &nbsp;
              </div>
              <div style="text-align: center; font-size: 0.55em">{{ item.name }}</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!---
    // Property side list
    ----->
    <v-row>
      <v-col v-if="propertiesList.length" class="pt-0">
        <v-row>
          <v-progress-linear
            :style="{ visibility: loadingPropertiesList ? 'visible' : 'hidden' }"
            indeterminate
          ></v-progress-linear>
          <div v-for="(item, index) in paginateProperties" :key="index" style="float: left; max-width: 200px">
            <v-card
              color="blue-grey lighten-5"
              class="ml-3 mr-3 mb-3 mx-auto"
              hover
              @click="getParcel(item.parcelnumb, item.mailadd)"
            >
              <v-card-text class="pt-0 pb-0">
                <v-row>
                  <v-col>
                    <h4 class="primary--text">{{ item.address }}</h4>
                    <div>{{ item.owner }}</div>
                    <h4>{{ item.usedesc }} - {{ item.zoning }}</h4>
                    <div>
                      <strong>{{ formatCurrency(item.saleprice) }}</strong> - {{ item.saledate }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageNumProperties"
              :length="Math.round(quickSearchProperties.length / pagingPropertiesPerPage) - 1"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import numeral from 'numeral';
import mapboxgl from 'mapbox-gl';
import { mapGetters } from 'vuex';
import FilterProperties from '@/components/FilterProperties';
import PropertyDetail from '@/components/PropertyDetail';
import PropertiesService from '@/services/properties.service';
import BaseLoader from '@/components/BaseLoader';

export default {
  name: 'PropertyExplorer',
  components: { FilterProperties, PropertyDetail, BaseLoader },
  data() {
    return {
      map: null,
      numeral,
      loadingPropertiesGeo: false,
      loadingPropertiesCluster: false,
      loadingPropertiesList: false,
      loadingLayers: false,
      mapSearchTimer: '',
      activeParcel: null,
      propertiesGeo: null,
      propertiesList: [],
      propertiesFilters: [],
      propertiesFilterCount: 0,
      propertiesMapCount: 0,
      propertyDetail: null,
      propertyQuickSearch: [],
      propertiesCluster: [],
      propertiesLayers: { zone_overlays: { features: [] }, streets: { features: [] }, ccm: { features: [] } },
      searchPropertiesText: '',
      textSearchTimer: '',
      parcelDialog: false,
      showContactInfo: false,
      contactList: [],
      mapType: 'light',
      mapTile: 'light-v10',
      search: '',
      tableKey: '1',
      searchPropertyListText: null,
      pageNumProperties: 1,
      pagingPropertiesPerPage: 6,
      maxZoom: 14,
      minZoom: 15,
      mapConnectorStreet: false,
      mapZoneOverlay: false,
      mapCCM: false,
      CCMLegend: [
        {
          name: 'D District',
          color: '#fbb03b',
        },
        {
          name: 'T3 Suburban',
          color: '#e55ea2',
        },
        {
          name: 'T4 Urban',
          color: '#e55e5e',
        },
        {
          name: 'T2 Rural',
          color: '#5ea2e5',
        },
        {
          name: 'T5 Center',
          color: '#5ee5a2',
        },
        {
          name: 'T6 Downtown',
          color: '#e55ee5',
        },
        {
          name: 'T1 Natural',
          color: '#e5e45e',
        },
        {
          name: 'Suburban',
          color: '#5e5fe5',
        },
        {
          name: 'W Water',
          color: '#e5a15e',
        },
        {
          name: 'Other',
          color: '#5ee5e5',
        },
      ],
      zoneOverlayLegend: [
        {
          name: 'Urban Design Overlay',
          color: '#fbb03b',
        },
        {
          name: 'Airport Impact',
          color: '#223b53',
        },
        {
          name: 'Historic Preservation Landmark',
          color: '#e55e5e',
        },
        {
          name: 'Bed and Breakfast',
          color: '#5ea2e5',
        },
        {
          name: 'Historic Preservation District',
          color: '#5ee5a2',
        },
        {
          name: 'I-440 Impact',
          color: '#e55ee5',
        },
        {
          name: 'Neighborhood Conservation',
          color: '#3bb2d0',
        },
        {
          name: 'Neighborhood Landmark',
          color: '#5e5fe5',
        },
        {
          name: 'Institutional Overlay',
          color: '#e5a15e',
        },
        {
          name: 'Adult Entertainment',
          color: '#5ee5e5',
        },
        {
          name: 'Contextual Overlay District',
          color: '#e55ea2',
        },
        {
          name: 'Historic Bed and Breakfast Homestay',
          color: '#e5e45e',
        },
        {
          name: 'Urban Zoning Overlay',
          color: '#eb9da8',
        },
        {
          name: 'Corridor Design Overlay',
          color: '#9debe0',
        },
        {
          name: 'Default',
          color: '#ccc',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
    // quick search grid list
    paginateProperties() {
      var index = (this.pageNumProperties - 1) * this.pagingPropertiesPerPage;
      return this.quickSearchProperties.slice(index, index + this.pagingPropertiesPerPage);
    },
    quickSearchProperties() {
      if (this.searchPropertyListText) {
        return this.propertiesList.filter((item) => {
          return this.searchPropertyListText
            .toLowerCase()
            .split(' ')
            .every((v) => item.owner.toLowerCase().includes(v));
        });
      } else {
        return this.propertiesList;
      }
    },
  },
  watch: {
    activeGeoId() {
      this.loadMap();
    },
  },
  mounted() {
    if (this.$store.state.activeGeoId) {
      this.loadMap();
    }
  },
  methods: {
    showPropertyFilters() {
      this.$store.commit('showPropertyFilters', true);
    },
    searchPropertiesAll() {
      if (this.searchPropertiesText == '' || this.searchPropertiesText.length > 3) {
        clearTimeout(this.textSearchTimer);
        this.textSearchTimer = setTimeout(this.searchPropertiesAllExe(), 500);
      }
    },
    // quick search properties
    searchPropertiesAllExe() {
      PropertiesService.searchProperties({ search: this.searchPropertiesText, geo_id: this.$store.state.activeGeoId })
        .then((response) => {
          this.propertyQuickSearch = response;
          console.log(this.propertyQuickSearch);
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
    // count properties
    async getPropertiesCount() {
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      PropertiesService.propertiesCount(postParams)
        .then((response) => {
          this.propertiesFilterCount = response.cnt;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Get Zone overlays for maps
    getLayersZoneOverlay() {
      this.loadingLayers = true;
      if (this.propertiesLayers.zone_overlays.features.length == 0) {
        PropertiesService.zoneoverlays({
          geoid: this.$store.state.activeGeoId,
          boundary: JSON.stringify(this.$store.state.propertyFilterConfig.boundary),
        })
          .then((response) => {
            this.propertiesLayers.zone_overlays = response.zone_overlays;
            this.loadingLayers = false;
          })
          .catch((error) => {
            console.log(error), (this.errored = true);
            this.loadingLayers = false;
          })
          .finally(() => this.updateZoneOverlays());
      } else {
        this.updateZoneOverlays();
        this.loadingLayers = false;
      }
    },
    // Get streets for maps to show zones
    getLayersStreets() {
      this.loadingLayers = true;
      if (this.propertiesLayers.streets.features.length == 0) {
        PropertiesService.streets({ geoid: this.$store.state.activeGeoId })
          .then((response) => {
            this.propertiesLayers.streets = response.streets;
            this.loadingLayers = false;
          })
          .catch((error) => {
            console.log(error), (this.errored = true);
            this.loadingLayers = false;
          })
          .finally(() => this.updateStreets());
      } else {
        this.updateStreets();
        this.loadingLayers = false;
      }
    },
    // Get streets for maps to show zones
    getLayersCCM() {
      this.loadingLayers = true;
      if (this.propertiesLayers.ccm.features.length == 0) {
        PropertiesService.ccm({
          geoid: this.$store.state.activeGeoId,
          boundary: JSON.stringify(this.$store.state.propertyFilterConfig.boundary),
        })
          .then((response) => {
            this.propertiesLayers.ccm = response.ccm;
            this.loadingLayers = false;
          })
          .catch((error) => {
            console.log(error), (this.errored = true);
            this.loadingLayers = false;
          })
          .finally(() => this.updateCCM());
      } else {
        this.updateCCM();
        this.loadingLayers = false;
      }
    },
    // Get detailed property
    getParcel(parcelnumb, mailadd) {
      console.log(mailadd);
      this.$store.commit('showLoading', true);
      PropertiesService.parcel({ geoid: this.$store.state.activeGeoId, parcel_num: parcelnumb })
        .then((response) => {
          this.$store.commit('setPropertyDetail', response);
          this.$store.commit('showPropertyDetail', true);
          this.$store.commit('showLoading', false);
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.$store.commit('showLoading', false);
        });
    },
    /**
     * DB search for any property
     */
    getParcelSearch(parcelnumb, mailadd) {
      var res = this.propertyQuickSearch.find((s) => s.parcelnumb === parcelnumb && s.mailadd === mailadd);
      this.$store.commit('setPropertyDetail', res);
      this.$store.commit('showPropertyDetail', true);
      this.parcelDialog = true;
      this.propertyQuickSearch = [];
      this.searchPropertiesText = [];
    },
    refreshData() {
      this.searchProperties();
      // may not want to call this twice
      this.getClusterProperties();
    },
    // main search properties method
    searchProperties() {
      // set map boundary
      this.setMapBoundary();
      // get init properties
      this.getPropertiesCount();
      // exit if zoomed out and only show clusters
      // turn back on if not showing dots
      if (this.map.getZoom() <= this.maxZoom) {
        this.getClusterProperties();
        this.propertiesList = [];
        return;
      }

      // only get map geo for map view
      this.updateMapParcels();
      // prep search string for properties
      /*
      this.loadingPropertiesList = true;
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      PropertiesService.filterProperties(postParams)
        .then((response) => {
          // need to add back in to show property list
          //this.propertiesList = response;
          console.log(response);
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.loadingPropertiesList = false;
          this.tableKey = '12';
        });
        */
    },
    // count properties
    getClusterProperties() {
      this.loadingPropertiesCluster = true;
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      PropertiesService.clusterProperties(postParams)
        .then((response) => {
          this.propertiesCluster = response;
          this.propertiesMapCount = this.propertiesCluster.features.length;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesCluster = false;
        })
        .finally(() => {
          this.updateClusters(), (this.errored = false);
          this.loadingPropertiesCluster = false;
        });
    },
    // Acre formatting
    formatAcre(val) {
      if (val >= this.acreMax) {
        return this.numeral(val).format('0.00') + '+';
      } else {
        return this.numeral(val).format('0.00');
      }
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Changing maps
    changeMaps() {
      if (this.mapType === 'street') {
        this.mapTile = 'streets-v11';
      } else if (this.mapType === 'light') {
        this.mapTile = 'light-v10';
      } else if (this.mapType == 'dark') {
        this.mapTile = 'dark-v10';
      } else if (this.mapType == 'street-view') {
        this.mapTile = 'dark-v10';
      }
      this.loadMap();
    },
    // Load initial map
    loadMap() {
      var vm = this;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiY2hhcG1hc3dlbXBvd2VyIiwiYSI6ImNraDZ0Z2M5eDAyY2kyeGsxYno1enU1MGgifQ.P6K0qDTB7-ccH0OaD4K2hg';
      // Init map settings
      this.map = new mapboxgl.Map({
        container: 'mapdiv',
        style: 'mapbox://styles/mapbox/' + this.mapTile + '',
        center: this.$store.state.activeGeoLongLat, //[-86.7816, 36.1627],
        // pitch: 50, // pitch in degrees
        zoom: 13,
      });

      // Add map controls
      this.map.addControl(new mapboxgl.NavigationControl());

      // On a map move event, zoom, etc get new data
      this.map.on('moveend', (e) => {
        clearTimeout(this.mapSearchTimer);
        if (
          e.originalEvent != 'UIEvent' &&
          this.loadingPropertiesList == false &&
          this.loadingPropertiesGeo == false &&
          this.loadingPropertiesCluster == false
        ) {
          console.log('call data');
          this.mapSearchTimer = setTimeout(function () {
            vm.searchProperties();
          }, 1200);
        }
      });

      // On map load event
      this.map.on('load', function () {
        // init map parcel data
        vm.searchProperties();
        // On click event show parcel details
        vm.map.on('click', 'parcels', function (e) {
          var coordinates = e.features[0].geometry.coordinates[0][0].slice();
          vm.activeParcel = e.features[0].properties.parcel;
          vm.mailaddr = e.features[0].properties.mailaddr;
          vm.getParcel(vm.activeParcel, vm.mailaddr);
          /*
            // Center map on button push
            vm.map.flyTo({
              center: e.features[0].geometry.coordinates[0][0].slice()
            });
            */
          // center popup over property
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Show mini popup to map
          // new mapboxgl.Popup().setLngLat(coordinates).setHTML(vm.activeParcel).addTo(vm.map);
        });

        /*
        // On mouseover add styles
        vm.map.on('mouseenter', 'parcels', function () {
          vm.map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        vm.map.on('mouseleave', 'parcels', function () {
          vm.map.getCanvas().style.cursor = '';
        });
        */
      });
    },
    // Load zone overlays
    updateZoneOverlays() {
      // init boundary
      this.setMapBoundary();
      var zoneOverlayMapLayer = this.map.getLayer('zoneoverlays');
      if (typeof zoneOverlayMapLayer !== 'undefined') {
        this.propertiesLayers.zone_overlays.features = [];
        this.map.removeLayer('zoneoverlays');
        this.map.removeSource('zoneoverlays');
      } else {
        this.map.addSource('zoneoverlays', {
          type: 'geojson',
          data: this.propertiesLayers.zone_overlays,
        });

        // Add parcel polygons
        this.map.addLayer({
          id: 'zoneoverlays',
          type: 'fill',
          source: 'zoneoverlays',
          //"minzoom": 15,
          layout: {},
          paint: {
            'fill-opacity': 0.4,
            'fill-color': [
              'match',
              ['get', 'zone'],
              'Urban Design Overlay',
              '#fbb03b',
              'Airport Impact',
              '#223b53',
              'Historic Preservation Landmark',
              '#e55e5e',
              'Bed and Breakfast',
              '#5ea2e5',
              'Historic Preservation District',
              '#5ee5a2',
              'I-440 Impact',
              '#e55ee5',
              'Neighborhood Conservation',
              '#e55ee5',
              'Neighborhood Landmark',
              '#5e5fe5',
              'Institutional Overlay',
              '#e5a15e',
              'Adult Entertainment',
              '#5ee5e5',
              'Contextual Overlay District',
              '#e55ea2',
              'Historic Bed and Breakfast Homestay',
              '#e5e45e',
              'Urban Zoning Overlay',
              '#eb9da8',
              'Corridor Design Overlay',
              '#9debe0',
              '#ccc',
            ],
          },
        });
        this.map.moveLayer('zoneoverlays', 'clusteroverlay');
      }
    },

    // Load street collectors
    updateStreets() {
      var streetsMapLayer = this.map.getLayer('streets');
      if (typeof streetsMapLayer !== 'undefined') {
        this.propertiesLayers.streets.features = [];
        this.map.removeLayer('streets');
        this.map.removeSource('streets');
      } else {
        this.map.addSource('streets', {
          type: 'geojson',
          data: this.propertiesLayers.streets,
        });

        // Add street polygons
        this.map.addLayer({
          id: 'streets',
          type: 'fill',
          source: 'streets',
          //"minzoom": 15,
          layout: {},
          paint: {
            'fill-opacity': 0.8,
            'fill-color': '#f54242',
          },
        });
        this.map.moveLayer('streets', 'clusteroverlay');
      }
    },

    // Load CCM
    updateCCM() {
      // reinit boundary
      this.setMapBoundary();
      var ccmMapLayer = this.map.getLayer('ccm');
      if (typeof ccmMapLayer !== 'undefined') {
        this.propertiesLayers.ccm.features = [];
        this.map.removeLayer('ccm');
        this.map.removeSource('ccm');
      } else {
        this.map.addSource('ccm', {
          type: 'geojson',
          data: this.propertiesLayers.ccm,
        });

        // Add parcel polygons
        this.map.addLayer({
          id: 'ccm',
          type: 'fill',
          source: 'ccm',
          //"minzoom": 15,
          layout: {},
          paint: {
            'fill-opacity': 0.4,
            'fill-color': [
              'match',
              ['get', 'transect'],
              'D District',
              '#fbb03b',
              'T3 Suburban',
              '#e55ea2',
              'T4 Urban',
              '#e55e5e',
              'T2 Rural',
              '#5ea2e5',
              'T5 Center',
              '#5ee5a2',
              'T6 Downtown',
              '#e55ee5',
              'T1 Natural',
              '#e5e45e',
              'Suburban',
              '#5e5fe5',
              'W Water',
              '#e5a15e',
              '#5ee5e5',
            ],
          },
        });
        this.map.moveLayer('ccm', 'clusteroverlay');
      }
    },

    // Load zone overlays
    updateClusters() {
      var clusterMapLayer = this.map.getLayer('clusteroverlay');
      if (typeof clusterMapLayer !== 'undefined') {
        this.map.getSource('clusters').setData(this.propertiesCluster);
      } else {
        this.map.addSource('clusters', {
          type: 'geojson',
          data: this.propertiesCluster,
          cluster: true,
          clusterRadius: 120,
          clusterMaxZoom: this.maxZoom,
        });
        // add cluster points
        this.map.addLayer({
          id: 'clusteroverlay',
          type: 'circle',
          source: 'clusters',
          //"minzoom": 15,
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': ['step', ['get', 'point_count'], '#e55e5e', 100, '#fbb03b', 750, '#3bb2d0'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          },
        });

        // add numbers to the clusters
        this.map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'clusters',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });

        // add points when unclustered
        /*
        this.map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'clusters',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#11b4da',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
        });
        */

        var vm = this;
        // inspect a cluster and zoom on click
        this.map.on('click', 'clusteroverlay', function (e) {
          var features = vm.map.queryRenderedFeatures(e.point, {
            layers: ['clusteroverlay'],
          });
          var clusterId = features[0].properties.cluster_id;
          vm.map.getSource('clusters').getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return;
            vm.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
        });
      }
    },

    // update map with new data as the user moves the map
    updateMapParcels() {
      var vm = this;
      vm.loadingPropertiesGeo = true;
      vm.propertiesGeo = [];
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      // get property parcels
      PropertiesService.geoProperties(postParams)
        .then((response) => {
          vm.propertiesGeo = response;
          vm.propertiesMapCount = vm.propertiesGeo.features.length;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesGeo = false;
        })
        .finally(() => {
          var mapLayer = vm.map.getLayer('parcels');
          if (typeof mapLayer !== 'undefined') {
            vm.map.getSource('parcels').setData(vm.propertiesGeo);
          } else {
            vm.map.addSource('parcels', {
              type: 'geojson',
              data: vm.propertiesGeo,
            });

            // add parcel polygons
            vm.map.addLayer({
              id: 'parcels',
              type: 'fill',
              source: 'parcels',
              minzoom: this.minZoom,
              layout: {},
              paint: {
                'fill-outline-color': '#333333',
                'fill-color': '#333333',
                'fill-opacity': 0.3,
              },
            });
          }
          this.loadingPropertiesGeo = false;
        });
    },
    // update map with new data as the user moves the map
    setMapBoundary() {
      if (this.map) {
        var $bounds = this.map.getBounds();
        var southWest = new mapboxgl.LngLat($bounds._sw.lng, $bounds._sw.lat);
        var northEast = new mapboxgl.LngLat($bounds._ne.lng, $bounds._ne.lat);
        var boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
        var northWest = boundingBox.getNorthWest();
        var southEast = boundingBox.getSouthEast();
        var mapBoundary = [];
        mapBoundary.push([parseFloat(northWest.lng.toFixed(5)), parseFloat(northWest.lat.toFixed(5))]);
        mapBoundary.push([parseFloat($bounds._ne.lng.toFixed(5)), parseFloat($bounds._ne.lat.toFixed(5))]);
        mapBoundary.push([parseFloat(southEast.lng.toFixed(5)), parseFloat(southEast.lat.toFixed(5))]);
        mapBoundary.push([parseFloat($bounds._sw.lng.toFixed(5)), parseFloat($bounds._sw.lat.toFixed(5))]);
        mapBoundary.push([parseFloat(northWest.lng.toFixed(5)), parseFloat(northWest.lat.toFixed(5))]);
        this.$store.commit('setMapBoundary', mapBoundary);
      } else {
        this.$store.commit('setMapBoundary', []);
      }
    },
    parseContactdate(str) {
      if (!/^(\d){8}$/.test(str)) return 'invalid date';
      var y = str.substr(0, 4),
        m = str.substr(4, 2),
        d = str.substr(6, 2);

      var ddate = new Date(y, m, d);
      return ddate.getMonth() + 1 + '/' + ddate.getDate() + '/' + ddate.getFullYear();
    },
  },
};
</script>
