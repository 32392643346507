<template>
  <div style="margin-top: 0px">
    <v-btn
      color="primary"
      style="position: fixed; top: 14px; left: 500px; z-index: 5"
      plain
      @click="showPropertyFilters"
      >Filter properties</v-btn
    >
    <v-badge
      v-if="propertiesFilterCount > 0"
      style="position: fixed; top: 22px; left: 670px; z-index: 5"
      color="error"
      :content="propertiesFilterCount.toLocaleString('en-US')"
    ></v-badge>
    <v-btn
      color="primary"
      style="position: fixed; top: 14px; left: 800px; z-index: 5"
      plain
      @click="showBatchList = !showBatchList"
      >Batches</v-btn
    >
    <v-badge
      v-if="batches"
      style="position: fixed; top: 22px; left: 896px; z-index: 5"
      color="error"
      :content="batches.length"
    ></v-badge>
    <v-text-field
      v-model="searchPropertiesText"
      style="position: fixed; top: 14px; right: 14px; z-index: 5"
      label="Search all properties"
      prepend-icon="mdi-home-search"
      hide-details
      outlined
      clearable
      dense
      @keyup="searchPropertiesAll()"
    ></v-text-field>
    <v-card
      v-if="propertyQuickSearch.length > 0"
      style="position: absolute; right: 5px; z-index: 5; top: 20px; width: 290px; height: 500px; overflow: auto"
    >
      <v-list class="lighten-3">
        <v-list-item v-for="(field, i) in propertyQuickSearch" :key="i">
          <v-list-item-content style="cursor: pointer" @click="getParcelSearch(field.parcelnumb, field.mailadd)">
            <v-list-item-title v-text="field.owner"></v-list-item-title>
            <v-list-item-subtitle v-text="field.address"></v-list-item-subtitle>
            <v-list-item-subtitle v-text="field.parcelnumb"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <filter-properties @refresh-data="searchProperties()" />
    <property-detail />
    <v-progress-linear
      :style="{ visibility: loadingPropertiesList ? 'visible' : 'hidden' }"
      indeterminate
    ></v-progress-linear>
    <!---
    // Grid view
    ---->
    <v-row v-if="activeGeoId">
      <v-col>
        <v-card v-if="propertiesList.length">
          <v-row>
            <v-col>
              <v-text-field
                v-model="searchPropertyListText"
                class="mb-0 pb-0 mt-0 pt-0 pl-5 pr-5"
                style="width: 500px"
                label="Search"
                append-icon="mdi-magnify"
                hide-details
                outlined
                clearable
                dense
              ></v-text-field>
            </v-col>
            <v-col style="text-align: right">
              <v-chip :ripple="false" color="secondary">
                {{ quickSearchProperties.length.toLocaleString('en-US') }} properties listed
              </v-chip>
            </v-col>
            <!---
            <v-col>
              <download-csv :data="quickSearchProperties" name="download_properties.csv">
                <v-icon>mdi-folder-download</v-icon>
              </download-csv>
            </v-col>
            --->
            <v-col>
              <v-btn color="secondary" plain @click="showBatch = true">Export properties</v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="showBatch" persistent max-width="450px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Export a batch</span>
              </v-card-title>
              <v-text-field
                v-model="batchFileName"
                class="mb-0 pb-0 mt-0 pt-0 pl-5 pr-5"
                label="Enter name"
                hide-details
                required
                outlined
                clearable
                dense
              ></v-text-field>
              <v-card-subtitle class="mt-3">
                {{ quickSearchProperties.length.toLocaleString('en-US') }} properties will attempt to be skip traced
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showBatch = false"> Close </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    createBatchExport();
                    showBatch = false;
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-if="batches" v-model="showBatchList">
            <base-loader />
            <v-card class="pa-5">
              <v-row>
                <v-col>
                  <span class="text-h5">Batches</span>
                  <v-simple-table fixed-header height="400px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-left">Number of records</th>
                          <th class="text-left">Process</th>
                          <th class="text-left">Skiptrace</th>
                          <th class="text-left">Finished</th>
                          <th class="text-left">Download</th>
                          <th class="text-left">Download Fitlered</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in batches" :key="item.id">
                          <td>{{ item.desc }}</td>
                          <td>{{ item.records }}</td>
                          <td>
                            <v-btn v-if="!item.ftpdown_started" @click="checkSkipTrace(item.id)">Check if ready</v-btn>
                            <div v-if="item.ftpdown_started">Completed</div>
                          </td>
                          <!---
                          <td>
                            <v-btn @click="checkSkipTraceCustom(item.id)">Check if ready</v-btn>
                          </td>
                          --->
                          <td v-if="item.ftpdown_started">{{ item.ftp_finished }}</td>
                          <td>{{ item.contactmerge_finished }}</td>
                          <td v-if="!item.contactmerge_finished"></td>
                          <td v-if="item.contactmerge_finished">
                            <v-btn @click="getBatchFile(item.id, item.name)">Download</v-btn>
                          </td>
                          <td v-if="item.contactmerge_finished && item.completed">
                            <v-btn @click="getBatchCustomFile(item.id, item.name)">Filtered</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-btn color="blue darken-1" text @click="showBatchList = false"> Close </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
          <v-data-table
            style="cursor: pointer"
            dense
            :headers="headers"
            :loading="loadingPropertiesList"
            loading-text="Loading... Please wait"
            multi-sort
            :items-per-page="30"
            :items="quickSearchProperties"
            :item-key="tableKey"
            :fixed-header="true"
            @click:row="handleDataTableClick"
          >
            <template v-slot:item.parval="{ item }">
              {{ formatCurrency(item.parval) }}
            </template>
            <template v-slot:item.is_llc="{ item }">
              <v-chip :color="getColor(item.is_llc)" dark>{{ item.is_llc }}</v-chip>
            </template>
            <template v-slot:item.is_skiptraced="{ item }">
              <v-chip :color="getColor(item.is_skiptraced)" dark>{{ item.is_skiptraced }}</v-chip>
            </template>
            <template v-slot:item.is_violation="{ item }">
              <v-chip :color="getColor(item.is_violation)" dark>{{ item.is_violation }}</v-chip>
            </template>
            <template v-slot:item.is_owneroccupied="{ item }">
              <v-chip :color="getColor(item.is_owneroccupied)" dark>{{ item.is_owneroccupied }}</v-chip>
            </template>
            <template v-slot:item.usps_vacancy="{ item }">
              <v-chip :color="getColor(item.usps_vacancy)" dark>{{ item.usps_vacancy }}</v-chip>
            </template>
            v
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapGetters } from 'vuex';
import BaseLoader from '@/components/BaseLoader';
import FilterProperties from '@/components/FilterProperties';
import PropertyDetail from '@/components/PropertyDetail';
import PropertiesService from '@/services/properties.service';
import BatchesService from '@/services/batches.service';

export default {
  name: 'PropertyGrid',
  components: { BaseLoader, FilterProperties, PropertyDetail },
  data() {
    return {
      map: null,
      numeral,
      loadingPropertiesList: false,
      loadingContactList: false,
      loadingContactDetails: false,
      activeParcel: null,
      propertiesGeo: null,
      propertiesList: [],
      propertiesFilterCount: 0,
      propertyDetail: null,
      propertyQuickSearch: [],
      searchPropertiesText: '',
      textSearchTimer: '',
      showContactInfo: false,
      contactList: [],
      search: '',
      headers: [
        { text: 'Owner', value: 'owner' },
        { text: 'Address', value: 'address' },
        { text: 'Assessed', value: 'parval' },
        { text: 'Acres', value: 'll_gisacre' },
        { text: 'Sold', value: 'saledate' },
        { text: 'LLC Owned', value: 'is_llc' },
        { text: 'Skiptraced', value: 'is_skiptraced' },
        { text: 'Violations', value: 'is_violation' },
        { text: 'Owner Occupied', value: 'is_owneroccupied' },
        { text: 'Vacant', value: 'usps_vacancy' },
      ],
      tableKey: '1',
      searchPropertyListText: null,
      pageNumProperties: 1,
      pagingPropertiesPerPage: 40,
      maxZoom: 14,
      contactDetails: [],
      batchFileName: '',
      showBatch: false,
      batches: [],
      showBatchList: false,
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
    quickSearchProperties() {
      if (this.searchPropertyListText) {
        return this.propertiesList.filter((item) => {
          return this.searchPropertyListText
            .toLowerCase()
            .split(' ')
            .every((v) => item.owner.toLowerCase().includes(v));
        });
      } else {
        return this.propertiesList;
      }
    },
  },
  watch: {
    activeGeoId() {
      this.getPropertiesCount();
      this.searchProperties();
      this.getBatches();
    },
  },
  mounted() {
    if (this.$store.state.activeGeoId) {
      this.getPropertiesCount();
      this.searchProperties();
      this.getBatches();
    }
  },
  methods: {
    // Show property filter modal
    showPropertyFilters() {
      this.$store.commit('showPropertyFilters', true);
    },
    // Colorize circles
    getColor(num) {
      if (num == 0 || num == 'N') {
        return 'grey';
      } else {
        return 'green';
      }
    },
    setGeoId(geoId) {
      this.$store.commit('setGeoId', { id: geoId });
    },
    searchPropertiesAll() {
      if (this.searchPropertiesText == '' || this.searchPropertiesText.length > 3) {
        clearTimeout(this.textSearchTimer);
        this.textSearchTimer = setTimeout(this.searchPropertiesAllExe(), 500);
      }
    },
    // Quick search properties
    searchPropertiesAllExe() {
      PropertiesService.searchProperties({ search: this.searchPropertiesText, geo_id: this.$store.state.activeGeoId })
        .then((response) => {
          this.propertyQuickSearch = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
    // count properties
    async getPropertiesCount() {
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      PropertiesService.propertiesCount(postParams)
        .then((response) => {
          this.propertiesFilterCount = response.cnt;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    getParcelSearch(parcelnumb, mailadd) {
      console.log(mailadd);
      PropertiesService.parcel({ geoid: this.$store.state.activeGeoId, parcel_num: parcelnumb })
        .then((response) => {
          console.log(response.parcel[0]);
          this.$store.commit('setPropertyDetail', response);
          this.$store.commit('showPropertyDetail', true);
          this.propertyQuickSearch = [];
          this.searchPropertiesText = [];
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        });
      /*
      var res = this.propertyQuickSearch.find((s) => s.parcelnumb === parcelnumb && s.mail_addstr === mail_addstr);
      this.$store.commit('setPropertyDetail', res);
      this.$store.commit('showPropertyDetail', true);
      */
    },
    // main search properties method
    searchProperties() {
      this.loadingPropertiesList = true;
      // Reset boundary from map view
      this.$store.commit('setMapBoundary', []);
      // update properties count
      this.getPropertiesCount();
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      PropertiesService.filterProperties(postParams)
        .then((response) => {
          this.propertiesList = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesList = false;
        })
        .finally(() => {
          this.loadingPropertiesList = false;
          this.tableKey = '12';
        });
    },
    // create a batch export
    createBatchExport() {
      this.$store.commit('showLoading', true);
      var postParams = JSON.stringify(this.$store.state.propertyFilterConfig);
      var parms = JSON.parse(postParams);
      parms['batch_name'] = this.batchFileName;
      PropertiesService.batchProperties(parms)
        .then((response) => {
          this.batchResult = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.$store.commit('showLoading', false);
          this.batchFileName = '';
          this.getBatches();
        });
    },
    // Get all filter data criteria
    getBatches() {
      BatchesService.getAll()
        .then((response) => {
          this.batches = response;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
    checkSkipTrace($batchId) {
      this.$store.commit('showLoading', true);
      BatchesService.refreshBatch({ batch_id: $batchId })
        .then((response) => {
          if (response == false) {
            this.getBatches();
          } else {
            this.getBatches();
          }
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => this.$store.commit('showLoading', false));
    },
    checkSkipTraceCustom($batchId) {
      this.$store.commit('showLoading', true);
      BatchesService.refreshBatchCustom({ batch_id: $batchId })
        .then((response) => {
          if (response == false) {
            this.getBatches();
          } else {
            this.getBatches();
          }
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => this.$store.commit('showLoading', false));
    },
    getBatchFile($batchId, $fileName) {
      this.$store.commit('showLoading', true);
      BatchesService.getBatchFileForDownload({ batch_id: $batchId })
        .then((response) => {
          const blob = new Blob([response], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = $fileName + '_downloaded.csv';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => this.$store.commit('showLoading', false));
    },
    getBatchCustomFile($batchId, $fileName) {
      this.$store.commit('showLoading', true);
      BatchesService.getBatchCustomFileForDownload({ batch_id: $batchId })
        .then((response) => {
          const blob = new Blob([response], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = $fileName + '_custom_downloaded.csv';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => this.$store.commit('showLoading', false));
    },
    // Acre formatting
    formatAcre(val) {
      if (val >= this.acreMax) {
        return this.numeral(val).format('0.00') + '+';
      } else {
        return this.numeral(val).format('0.00');
      }
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // used in data table for click event
    handleDataTableClick(event) {
      console.log(event.mailadd);
      PropertiesService.parcel({ geoid: this.$store.state.activeGeoId, parcel_num: event.parcelnumb })
        .then((response) => {
          console.log(response.parcel[0]);
          this.$store.commit('setPropertyDetail', response);
          this.$store.commit('showPropertyDetail', true);
          this.propertyQuickSearch = [];
          this.searchPropertiesText = [];
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        });
    },
    parseContactdate(str) {
      if (!/^(\d){8}$/.test(str)) return 'invalid date';
      var y = str.substr(0, 4),
        m = str.substr(4, 2),
        d = str.substr(6, 2);

      var ddate = new Date(y, m, d);
      return ddate.getMonth() + 1 + '/' + ddate.getDate() + '/' + ddate.getFullYear();
    },
  },
};
</script>
