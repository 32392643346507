<template>
  <div style="margin-top: 0px; position: relative">
    <v-row>
      <v-col>
        <h2 class="secondary--text">Davidson County, TN</h2>
        <h3 v-if="neighborhoodStat" class="primary--text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ neighborhoodStat.neighborhood }}
        </h3>
      </v-col>
      <v-col>
        <div style="width: 250px">
          <v-select
            v-model="neighborhoodId"
            width="200px"
            class="mt-0"
            :items="neighborhoods"
            dense
            item-text="name"
            item-value="id"
            outlined
            label="Select a district"
            @input="selectNeighborhood"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="neighborhoodId" class="pa-0 pt-0">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row v-if="neighborhoodId" class="pa-3 pb-5">
      <base-card-chart
        :key="neighborhoodStat.neighborhood"
        name="permits-issued"
        :value="Number(neighborhoodStat.permits_issued)"
      />
      <base-card-chart
        :key="neighborhoodStat.neighborhood"
        name="property-count"
        :value="Number(neighborhoodStat.property_count)"
      />
      <base-card-chart
        :key="neighborhoodStat.neighborhood"
        name="nightlyrate-mean"
        :value="Number(neighborhoodStat.nightlyrate_mean)"
      />
      <base-card-chart
        :key="neighborhoodStat.neighborhood"
        name="occupancyrate-mean"
        :value="Number(neighborhoodStat.occupancyrate_mean)"
      />
      <base-card-chart
        :key="neighborhoodStat.neighborhood"
        name="annualrevenue-mean"
        :value="Number(neighborhoodStat.annualrevenue_mean)"
      />
      <base-card-chart
        :key="neighborhoodStat.neighborhood"
        name="average-days-booking"
        :value="Number(neighborhoodStat.average_days_booking)"
      />
    </v-row>
    <!---
    <v-row>
      <v-col>
        <div style="float: left; margin-top: 20px; font-size: 12px; font-weight: bold">Market Data</div>
        <div style="float: left; margin-left: 20px; margin-top: 10px; text-align: center; font-size: 26px">&#187;</div>
      </v-col>
    </v-row>
    --->
    <div
      class="secondary"
      style="
        position: absolute;
        bottom: 40px;
        z-index: 99;
        padding: 20px;
        width: 100%;
        border: 1px grey solid;
        border-right: none;
        border-left: none;
      "
    >
      <div style="width: 500px; margin: 0 auto">
        <div style="float: left; padding-top: 8px">
          <h4 style="color: #ffffff">Need <u>Custom Data?</u> Connect with our team!</h4>
        </div>
        <div style="float: left; margin-left: 40px">
          <v-btn elevation="2" class="primary">Get Started</v-btn>
        </div>
      </div>
    </div>
    <div style="margin-top: -20px">
      <str-detail key="detailKey" />
      <!----
      // Map view
      ------>
      <v-row>
        <v-col class="pt-0">
          <v-row>
            <v-col style="padding-top: 0px">
              <v-progress-linear
                :style="{ visibility: loadingPropertiesGeo | loadingNeighborhoodCluster ? 'visible' : 'hidden' }"
                indeterminate
              ></v-progress-linear>
              <!---
              // Map container
              ---->
              <div id="mapdiv" style="width: 100%; height: 620px"></div>
              <!---
              // Map legend
              --->
              <div class="cus-legend">
                <div class="primary--text cus-legend-title">Average Daily Rate</div>
                <div class="cus-legend-colorbar">
                  <div class="cus-legend-text" style="float: left">Low</div>
                  <div class="cus-legend-green"></div>
                  <div class="cus-legend-yellow"></div>
                  <div class="cus-legend-red"></div>
                  <div class="cus-legend-text" style="float: left">High</div>
                </div>
              </div>
            </v-col>
          </v-row>
          <!---
          // Property side list
          ----->
          <div
            v-if="!drawer && propertiesList.length > 0"
            style="
              position: absolute;
              right: 0px;
              z-index: 99;
              background: #fff;
              height: 166px;
              top: 280px;
              padding: 6px;
              border-radius: 16px 0 0 16px;
              cursor: pointer;
            "
            @click.stop="drawer = true"
          >
            <div
              style="
                writing-mode: vertical-rl;
                text-orientation: mixed;
                margin-top: 20px;
                font-size: 12px;
                font-weight: bold;
              "
            >
              Property Listing
            </div>
            <div style="margin-top: 10px; text-align: center; font-size: 26px">&#187;</div>
          </div>
          <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            right
            class="pa-5 pt-0 pb-0"
            height="712"
            width="420"
          >
            <v-row>
              <v-col>LISTED RENTALS</v-col>
            </v-row>
            <v-row>
              <v-col v-if="propertiesList.length > 0" class="pt-0">
                <v-row>
                  <v-progress-linear
                    :style="{ visibility: loadingPropertiesList ? 'visible' : 'hidden' }"
                    indeterminate
                  ></v-progress-linear>
                  <div v-for="(item, index) in paginateProperties" :key="index">
                    <v-card class="pb-2 mx-auto" style="box-shadow: none" @click="getParcel(item.propId)">
                      <v-row no-gutters class="mt-2">
                        <v-col cols="5">
                          <v-img
                            :src="item.allImageUrls.split(',')[0]"
                            :lazy-src="item.allImageUrls.split(',')[0]"
                            width="255px"
                            height="118px"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                            <span
                              style="
                                font-weight: bold;
                                bottom: 6px;
                                position: absolute;
                                left: 18px;
                                background: #fff;
                                border-radius: 4px;
                                padding: 2px;
                                font-size: 12px;
                              "
                            >
                              {{ formatCurrency(item.annualRevenue) }}
                            </span>
                            <span
                              style="
                                position: absolute;
                                bottom: 6px;
                                font-size: 10px;
                                color: #fff;
                                right: 6px;
                                font-weight: bold;
                                text-transform: uppercase;
                              "
                            >
                              Annual Revenue</span
                            >
                          </v-img>
                        </v-col>
                        <v-col>
                          <v-card-subtitle
                            class="pa-0 pl-3 font-weight-bold"
                            style="word-break: break-word; line-height: 16px"
                          >
                            {{ item.listingTitle }}</v-card-subtitle
                          >
                          <v-chip small class="ml-3 mb-1 mt-1">{{ item.neighborhood }}</v-chip>
                          <v-card-subtitle class="text-sm-body-custom font-weight-medium pa-0 pl-3"
                            >{{ formatDecimal(item.occupancyRate * 100) }}% Occupany Rate</v-card-subtitle
                          >
                          <v-card-subtitle class="text-sm-body-custom font-weight-medium pa-0 pl-3"
                            >{{ formatCurrency(item.annualRevenue) }} Estimated Annual Income</v-card-subtitle
                          >
                          <v-card-subtitle class="text-sm-body-custom font-weight-medium pa-0 pl-3"
                            >{{ formatCurrency(item.publishNightlyRate) }} Nightly Rate</v-card-subtitle
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-divider style="padding: 5px"></v-divider>
                  </div>
                </v-row>
                <v-row>
                  <v-col>
                    <v-pagination
                      v-model="pageNumProperties"
                      :length="Math.round(propertiesList.length / pagingPropertiesPerPage) - 1"
                      :total-visible="6"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-navigation-drawer>
          <!---
          <v-row>
            <v-col>
              <v-radio-group v-model="mapType" class="pt-0 mt-0" row @change="changeMaps">
                <v-radio label="Street" value="street"></v-radio>
                <v-radio label="Light" value="light"></v-radio>
                <v-radio label="Dark" value="dark"></v-radio>
                <v-radio label="Street View" value="street-view"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          --->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import mapboxgl from 'mapbox-gl';
import { mapGetters } from 'vuex';
import StrDetail from '@/components/StrDetail';
//import BasePieChart from '@/components/BasePieChart';
import BaseCardChart from '@/components/BaseCardChart';
import NeighborhoodsService from '@/services/neighborhoods.service';
import StrsService from '@/services/strs.service';

export default {
  name: 'WidgetExplorerCluster',
  components: { StrDetail, BaseCardChart },
  data() {
    return {
      map: null,
      drawer: false,
      numeral,
      loadingPropertiesGeo: false,
      loadingPropertiesList: false,
      mapSearchTimer: '',
      activeParcel: null,
      propertiesGeo: [],
      propertiesList: [],
      propertiesMapCount: 0,
      propertiesLayers: { zone_overlays: { features: [] }, streets: { features: [] } },
      searchPropertiesText: '',
      textSearchTimer: '',
      parcelDialog: false,
      showContactInfo: false,
      contactList: [],
      mapType: 'dark',
      mapTile: 'light-v10',
      search: '',
      tableKey: '1',
      pageNumProperties: 1,
      pagingPropertiesPerPage: 4,
      maxZoom: 15,
      neighborhood: '',
      neighborhoods: [],
      neighborhoodStats: [],
      neighborhoodId: '',
      neighborhoodGeos: [],
      loadingNeighborhoodCluster: false,
      markersOnScreen: {},
      markers: {},
      colors: ['#e55e5e', '#fbb03b', '#3bb2d0'],
      detailKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
    // quick search grid list
    paginateProperties() {
      var index = (this.pageNumProperties - 1) * this.pagingPropertiesPerPage;
      return this.propertiesList.slice(index, index + this.pagingPropertiesPerPage);
    },
  },
  watch: {
    activeGeoId() {
      this.loadMap();
    },
  },
  mounted() {
    this.getNeighborhoods();
    this.getNeighborhoodStats();
    if (this.$store.state.activeGeoId) {
      this.loadMap();
    }
  },
  methods: {
    // Load initial map
    loadMap() {
      var vm = this;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiY2hhcG1hc3dlbXBvd2VyIiwiYSI6ImNraDZ0Z2M5eDAyY2kyeGsxYno1enU1MGgifQ.P6K0qDTB7-ccH0OaD4K2hg';
      // Init map settings
      this.map = new mapboxgl.Map({
        container: 'mapdiv',
        style: 'mapbox://styles/mapbox/' + this.mapTile + '',
        center: this.$store.state.activeGeoLongLat,
        zoom: 13,
      });

      // Add map controls
      this.map.addControl(new mapboxgl.NavigationControl());
      // On map load event
      this.map.on('load', function () {
        /*
        const layers = this.map.getStyle().layers;
        const labelLayerId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;
        this.map.addLayer(
          {
            id: 'add-3d-buildings',
            source: 'composite',
            'source-layer': 'building',
            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoom: 15,
            paint: {
              'fill-extrusion-color': '#aaa',
              'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
              'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
              'fill-extrusion-opacity': 0.6,
            },
          },
          labelLayerId,
        );
        */
        // init map parcel data
        vm.searchProperties();
        // only get map geo for map view
        vm.updateMapParcels();

        // On Move map get detailed propery listings
        vm.map.on('moveend', (e) => {
          clearTimeout(vm.mapSearchTimer);
          if (e.originalEvent != 'UIEvent' && vm.loadingPropertiesList == false) {
            vm.mapSearchTimer = setTimeout(function () {
              vm.searchProperties();
            }, 2000);
          }
        });
        vm.map.on('render', () => {
          if (vm.map.getSource('earthquakes') && !vm.map.isSourceLoaded('earthquakes')) {
            return;
          }
          vm.updateMarkers();
        });
      });
    },
    // Get detailed property
    getParcel(propId) {
      this.detailKey++;
      var res = this.propertiesList.find((s) => s.propId === propId);
      console.log(res);
      this.$store.commit('setStrDetail', res);
      this.$store.commit('showStrDetail', true);
    },
    // Get all neighborhoods
    async getNeighborhoods() {
      NeighborhoodsService.getNeighborhoods()
        .then((response) => {
          this.neighborhoods = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Get all neighborhoods
    async getNeighborhoodStats() {
      NeighborhoodsService.getNeighborhoodsStats()
        .then((response) => {
          this.neighborhoodStats = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Select the neighborhood
    selectNeighborhood() {
      // Get specific neighborhood
      let neighborhood = this.neighborhoods.filter((item) => {
        return parseInt(item.id) === parseInt(this.neighborhoodId);
      });
      this.neighborhood = neighborhood;

      // get neighborhood stats
      this.neighborhoodStat = this.neighborhoodStats.filter((item) => {
        return item.neighborhood === neighborhood[0]['name'];
      })[0];

      var poly = [];
      for (var i = 0; i < neighborhood[0].geopolygon[0].length; i++) {
        poly.push([neighborhood[0].geopolygon[0][i].x, neighborhood[0].geopolygon[0][i].y]);
      }

      var districtOverlayMapLayer = this.map.getLayer('district-fill');
      if (typeof districtOverlayMapLayer !== 'undefined') {
        this.map.removeLayer('district-fill');
        this.map.removeLayer('district-outline');
        this.map.removeSource('district-data');
      }

      this.map.addSource('district-data', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [poly],
          },
        },
      });
      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'district-fill',
        type: 'fill',
        source: 'district-data', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#223b53', // blue color fill
          'fill-opacity': 0.1,
        },
      });
      this.map.addLayer({
        id: 'district-outline',
        type: 'line',
        source: 'district-data',
        layout: {},
        paint: {
          'line-color': '#223b53',
          'line-width': 1,
        },
      });

      var latlong = [parseFloat(neighborhood[0].lng), parseFloat(neighborhood[0].lat)];
      this.$store.commit('setNeighborhood', neighborhood[0]);
      this.map.flyTo({ center: latlong, zoom: 13 });
    },
    refreshData() {
      this.searchProperties();
      // may not want to call this twice
      //this.getClusterNeighborhoods();
    },
    // main search properties method
    searchProperties() {
      // exit if zoomed out and only show clusters
      // turn back on if not showing dots
      if (this.map.getZoom() < this.maxZoom) {
        return;
      }

      // set map boundary
      this.setMapBoundary();
      this.loadingPropertiesList = true;
      StrsService.getStrs()
        .then((response) => {
          this.propertiesList = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.loadingPropertiesList = false;
          this.tableKey = '12';
        });
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Decimal formatting
    formatDecimal(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Changing maps
    changeMaps() {
      if (this.mapType === 'street') {
        this.mapTile = 'streets-v11';
      } else if (this.mapType === 'light') {
        this.mapTile = 'light-v10';
      } else if (this.mapType == 'dark') {
        this.mapTile = 'dark-v10';
      } else if (this.mapType == 'street-view') {
        this.mapTile = 'dark-v10';
      }
      this.loadMap();
    },
    // update map with new data as the user moves the map
    updateMapParcels() {
      var vm = this;
      const mag1 = ['==', ['get', 'occupancyRate_index'], 1];
      const mag2 = ['==', ['get', 'occupancyRate_index'], 2];
      const mag3 = ['==', ['get', 'occupancyRate_index'], 3];
      vm.loadingPropertiesGeo = true;
      vm.propertiesGeo = [];

      // Get str geo placements
      StrsService.getStrsGeo()
        .then((response) => {
          vm.propertiesGeo = response.data;
          vm.propertiesMapCount = vm.propertiesGeo.length;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesGeo = false;
        })
        .finally(() => {
          vm.map.addSource('earthquakes', {
            type: 'geojson',
            data: vm.propertiesGeo,
            cluster: true,
            clusterRadius: 120,
            clusterMaxZoom: 16,
            clusterProperties: {
              mag1: ['+', ['case', mag1, 1, 0]],
              mag2: ['+', ['case', mag2, 1, 0]],
              mag3: ['+', ['case', mag3, 1, 0]],
            },
          });
          // Add Circle final dots layer
          vm.map.addLayer({
            id: 'earthquake_circle',
            type: 'circle',
            source: 'earthquakes',
            paint: {
              'circle-color': [
                'case',
                mag1,
                this.colors[0],
                mag2,
                this.colors[1],
                mag3,
                this.colors[2],
                this.colors[2],
              ],
              'circle-opacity': 1,
              'circle-radius': 24,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#fff',
            },
          });

          vm.map.addLayer({
            id: 'earthquake_label',
            type: 'symbol',
            source: 'earthquakes',
            layout: {
              'text-field': [
                'number-format',
                ['get', 'publishNightlyRate'],
                { currency: 'USD', 'max-fraction-digits': 0 },
              ],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
            paint: {
              'text-color': ['case', ['<', ['get', 'mag'], 3], 'white', 'white'],
            },
          });

          vm.map.on('click', 'earthquake_label', (e) => {
            vm.propId = e.features[0].properties.propId;
            vm.getParcel(vm.propId);
          });

          // the mouse is over the states layer.
          vm.map.on('mouseenter', 'earthquake_label', () => {
            vm.map.getCanvas().style.cursor = 'pointer';
          });
          // Change the cursor back to a pointer
          // when it leaves the states layer.
          vm.map.on('mouseleave', 'earthquake_label', () => {
            vm.map.getCanvas().style.cursor = '';
          });

          this.loadingPropertiesGeo = false;
        });
    },
    updateMarkers() {
      const newMarkers = {};
      const features = this.map.querySourceFeatures('earthquakes');

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        if (!props.cluster) continue;
        const id = props.cluster_id;
        let marker = this.markers[id];
        if (!marker) {
          const el = this.createDonutChart(props);
          marker = this.markers[id] = new mapboxgl.Marker({
            element: el,
          }).setLngLat(coords);
        }
        newMarkers[id] = marker;
        if (!this.markersOnScreen[id]) marker.addTo(this.map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in this.markersOnScreen) {
        if (!newMarkers[id]) this.markersOnScreen[id].remove();
      }
      this.markersOnScreen = newMarkers;
    },

    createDonutChart(props) {
      const offsets = [];
      const counts = [props.mag1, props.mag2, props.mag3];
      let total = 0;
      for (const count of counts) {
        offsets.push(total);
        total += count;
      }
      const fontSize = total >= 1000 ? 24 : total >= 100 ? 20 : total >= 10 ? 18 : 14;
      const r = total >= 1000 ? 70 : total >= 500 ? 60 : total >= 200 ? 50 : total >= 50 ? 40 : total >= 10 ? 30 : 24;
      const r0 = Math.round(r * 0.6);
      const w = r * 2;
      let html = `<div>
      <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;
      for (let i = 0; i < counts.length; i++) {
        html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, this.colors[i]);
      }
      html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
      <text dominant-baseline="central" transform="translate(${r}, ${r})">
      ${total.toLocaleString()}
      </text>
      </svg>
      </div>`;
      const el = document.createElement('div');
      el.innerHTML = html;
      return el.firstChild;
    },

    donutSegment(start, end, r, r0, color) {
      if (end - start === 1) end -= 0.00001;
      const a0 = 2 * Math.PI * (start - 0.25);
      const a1 = 2 * Math.PI * (end - 0.25);
      const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
      const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
      const largeArc = end - start > 0.5 ? 1 : 0;
      // draw an SVG path
      return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${
        r + r * x1
      } ${r + r * y1} L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
        r + r0 * y0
      }" fill="${color}" />`;
    },
    // update map with new data as the user moves the map
    setMapBoundary() {
      if (this.map) {
        var $bounds = this.map.getBounds();
        var southWest = new mapboxgl.LngLat($bounds._sw.lng, $bounds._sw.lat);
        var northEast = new mapboxgl.LngLat($bounds._ne.lng, $bounds._ne.lat);
        var boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
        var northWest = boundingBox.getNorthWest();
        var southEast = boundingBox.getSouthEast();
        var mapBoundary = [];
        mapBoundary.push([parseFloat(northWest.lng.toFixed(5)), parseFloat(northWest.lat.toFixed(5))]);
        mapBoundary.push([parseFloat($bounds._ne.lng.toFixed(5)), parseFloat($bounds._ne.lat.toFixed(5))]);
        mapBoundary.push([parseFloat(southEast.lng.toFixed(5)), parseFloat(southEast.lat.toFixed(5))]);
        mapBoundary.push([parseFloat($bounds._sw.lng.toFixed(5)), parseFloat($bounds._sw.lat.toFixed(5))]);
        mapBoundary.push([parseFloat(northWest.lng.toFixed(5)), parseFloat(northWest.lat.toFixed(5))]);
        this.$store.commit('setMapBoundary', mapBoundary);
      } else {
        this.$store.commit('setMapBoundary', []);
      }
    },
  },
};
</script>
<style>
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}
</style>
<style scoped>
.text-sm-body-custom {
  font-size: 0.775rem;
  line-height: 14px;
}
.cus-legend {
  position: absolute;
  bottom: 120px;
  left: 20px;
  z-index: 999;
}
.cus-legend-title {
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
}
.cus-legend-colorbar {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
}
.cus-legend-text {
  color: white;
  font-weight: bold;
  font-size: 0.85em;
  padding: 5px;
}
.cus-legend-green,
.cus-legend-yellow,
.cus-legend-red {
  margin: 5px;
  width: 20px;
  height: 20px;
  background: #3bb2d0;
  float: left;
  border: 1px solid #eaeaea;
}
.cus-legend-yellow {
  background: #fbb03b;
}
.cus-legend-red {
  background: #e55e5e;
}
</style>
