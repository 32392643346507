import axios from 'axios';
import { PRODUCTION_WIDGET_API_URL } from '@/settings';

const API_URL = PRODUCTION_WIDGET_API_URL + 'str';

class StrsService {
  getStrs() {
    return axios.get(API_URL + '/').then((response) => {
      return response.data;
    });
  }
  getStrsGeo() {
    return axios.get(API_URL + '/geo').then((response) => {
      return response.data;
    });
  }
}

export default new StrsService();
