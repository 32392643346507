import Vue from 'vue';
import App from '@/App.vue';
import axios from 'axios';
import store from '@/store';
import VueAxios from 'vue-axios';
import vuetify from '@/plugins/vuetify';
import './vee-validate';
import router from '@/router.js';
import JsonCSV from 'vue-json-csv';
import moment from 'moment';

Vue.component('downloadCsv', JsonCSV);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueAxios, axios);

Vue.filter('phone', function (phone) {
  return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY');
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
