<template>
  <div class="container">
    <header class="jumbotron"></header>
    <br />
    <header class="jumbotron">
      <v-btn color="primary" plain :to="{ name: 'propertyexplorer' }">Map explorer</v-btn>
    </header>
    <br />
    <header class="jumbotron">
      <v-btn color="primary" plain :to="{ name: 'propertygrid' }">Grid explorer</v-btn>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      content: '',
    };
  },
  mounted() {},
};
</script>
