<template>
  <div>
    <base-loader />
    <base-toast />
    <v-row class="text-center">
      <v-col> </v-col>
      <v-col valign="center">
        <div class="card card-container">
          <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit, validate }">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Create account </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <ValidationProvider v-slot="{ errors, valid }" name="First name" :rules="{ required: true, min: 2 }">
                  <v-text-field
                    v-model="user.firstName"
                    :error-messages="errors"
                    :success="valid"
                    label="First name"
                    required
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors, valid }" name="Last name" :rules="{ required: true, min: 2 }">
                  <v-text-field
                    v-model="user.lastName"
                    :error-messages="errors"
                    :success="valid"
                    label="Last name"
                    required
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors, valid }" name="email" rules="required|email">
                  <v-text-field
                    v-model="user.username"
                    :error-messages="errors"
                    :success="valid"
                    label="Username (email)"
                    required
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="Password"
                  :rules="{ password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/, required: true, min: 6 }"
                >
                  <v-text-field
                    v-model="user.password"
                    :counter="6"
                    :error-messages="errors"
                    :success="valid"
                    label="Password"
                    type="password"
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="clear">Clear</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="!validated || invalid" color="primary" @click="validate()">Sign in</v-btn>
                <v-btn v-if="!invalid && validated" color="primary" @click="handleSubmit(register)">Sign up</v-btn>
              </v-card-actions>
              <v-row class="mt-3">
                <v-col><router-link to="/login"> Back to login </router-link> </v-col>
              </v-row>
            </v-card>
          </ValidationObserver>
        </div>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
</template>

<script>
import User from '../models/user';
import BaseLoader from '@/components/BaseLoader';
import BaseToast from '@/components/BaseToast';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Register',
  components: {
    BaseLoader,
    BaseToast,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      user: new User('', '', '', ''),
      successful: false,
      message: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/home');
    }
  },
  methods: {
    async clear() {
      this.password = this.email = this.username = this.firstName = this.lastName = '';
      requestAnimationFrame(() => {
        this.$refs.obs.reset();
      });
    },
    async register() {
      this.message = '';
      this.$store.commit('showLoading', true);
      this.$store.dispatch('auth/register', this.user).then(
        () => {
          this.successful = true;
          this.$store.commit('showLoading', false);
          this.$store.commit('showToast', { active: true, message: 'Account created successfully.' });
          this.$router.push('/login');
        },
        (error) => {
          console.log(error.response);
          this.message = (error.response && error.response.data.message) || error.toString();
          this.successful = false;
          this.$store.commit('showLoading', false);
          this.$store.commit('showToast', { active: true, message: this.message });
        },
      );
    },
  },
};
</script>
